
class DocumentSocket {
    constructor(documentId, documentType, documentStatus, documentData) {
        
        this._documentId = documentId;
        this._documentType = documentType;
        this._documentStatus = documentStatus;
        this._documentData = documentData;

    }

    get documentId()
    {
        return this._documentId;
    }

    get documentType()
    {
        return this._documentType;
    }

    get documentStatus()
    {
        return this._documentStatus;
    }

    get documentData()
    {
        return this._documentData;
    }

    toJSON()
    {
        return {
            documentId: this._documentId,
            documentType: this._documentType,
            documentStatus: this._documentStatus,
            documentData: this._documentData
        }
    }


}
module.exports = DocumentSocket;