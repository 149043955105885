<template>

  <div id="newSessionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="session" class="modal-title">{{ $t("session.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div v-if="session" class="modal-body">
          <p>{{ $t("session.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("session.new.nameLabel") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="session.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>
            <div class="form-group mb-3">
              <label for="slugInput">{{ $t("session.new.slugLabel") }}</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': slugError, 'is-valid': slugSuccess }" id="slugInput" v-model="session.slug" @input="testSlug()">
              <div v-if="slugError" class="invalid-feedback">
                {{slugError}}
              </div>
              <div v-if="slugSuccess" class="valid-feedback">
                {{slugSuccess}}
              </div>
            </div>

            
            <div class="form-group mb-3">
              <div class="form-check">
                <input class="form-check-input" type="radio" name="signinNew" id="signinEmailNew" value="EMAIL" v-model="session.signin">
                <label class="form-check-label" for="signinEmailNew">
                  {{ $t("session.new.signinEmailLabel") }}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="signinNew" id="signinExternalIdNew" value="EXTERNALID" v-model="session.signin">
                <label class="form-check-label" for="signinExternalIdNew">
                  {{ $t("session.new.signinExternalIdLabel") }}
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="signinNew" id="signinPublicNew" value="PUBLIC" v-model="session.signin">
                <label class="form-check-label" for="signinPublicNew">
                  {{ $t("session.new.signinPublicLabel") }}
                </label>
              </div>
            </div>

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="enabledNew" v-model="session.enabled">
                <label class="form-check-label" for="enabledNew">{{ $t("session.new.enabledSessionLabel") }}</label>
              </div>
            </div>  

            <div class="form-group mb-3">
              <label for="startDateInput">{{ $t("session.new.startDateLabel") }} <small>({{ $t("session.new.your_timezone") }} {{ timeZone }})</small></label>
              <input id="startDateInput" type="datetime-local" class="form-control" :class="{ 'is-invalid': startDateError}" v-model="session.startDate">      
              <div v-if="startDateError" class="invalid-feedback">
                {{startDateError}}
              </div>  
              
              <label for="endDateInput">{{ $t("session.new.endDateLabel") }} <small>({{ $t("session.new.your_timezone") }} {{ timeZone }})</small></label>
              <input id="endDateInput" type="datetime-local" class="form-control" :class="{ 'is-invalid': endDateError}" v-model="session.endDate">      
              <div v-if="endDateError" class="invalid-feedback">
                {{endDateError}}
              </div>  
            </div>            

            <div class="form-group mb-3">
              <label for="emailInput">{{ $t("session.new.languageLabel") }}</label>
              <select class="form-select form-select-sm"  v-model="session.language">
                <option v-for="(locale, index) in $i18n.availableLocales" v-bind:key="index" v-bind:value="locale">{{ locale }}</option>
              </select>
              <div v-if="languageError" class="invalid-feedback">
                {{languageError}}
              </div>   
            </div>
            

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="qasActivated" v-model="session.qasActivated">
                <label class="form-check-label" for="qasActivated">{{ $t("session.new.QaActivatedLabel") }}</label>
              </div>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="inprogressAccepted" v-model="session.inprogressAccepted">
                <label class="form-check-label" for="inprogressAccepted">{{ $t("session.new.JoinDuringSessionLabel") }}</label>
              </div>
            </div>  
            
            
            <div class="form-group mb-3">
              <label for="logo">{{ $t("session.new.sessionLogoLabel") }}</label>
              <input id="logo" type="text" class="form-control" placeholder="https://" :class="{ 'is-invalid': logoError}" v-model="session.logo">      
              <div v-if="logoError" class="invalid-feedback">
                {{logoError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="icon">{{ $t("session.new.sessionIconLabel") }}</label>
              <input id="icon" type="text" class="form-control" placeholder="https://" :class="{ 'is-invalid': iconError}" v-model="session.icon">      
              <div v-if="iconError" class="invalid-feedback">
                {{iconError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="textColor">{{ $t("session.new.sessionTextColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="textColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': textColorError}" v-model="session.textColor">      
                <color-picker v-model:pureColor="session.textColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />
              </div>
              <div v-if="textColorError" class="invalid-feedback">
                {{textColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="helpColor">{{ $t("session.new.sessionHelpColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="helpColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': helpColorError}" v-model="session.helpColor">      
                <color-picker v-model:pureColor="session.helpColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />
              </div>
              <div v-if="helpColorError" class="invalid-feedback">
                {{helpColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="elementTextColor">{{ $t("session.new.sessionElementTextColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="elementTextColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': elementTextColorError}" v-model="session.elementTextColor">
                <color-picker v-model:pureColor="session.elementTextColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />      
              </div>
              <div v-if="elementTextColorError" class="invalid-feedback">
                {{elementTextColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="elementTextActiveColor">{{ $t("session.new.sessionElementTextActiveColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="elementTextActiveColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': elementTextActiveColorError}" v-model="session.elementTextActiveColor">      
                <color-picker v-model:pureColor="session.elementTextActiveColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />
              </div>
              <div v-if="elementTextActiveColorError" class="invalid-feedback">
                {{elementTextActiveColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="elementBackgroundColor">{{ $t("session.new.sessionElementBackgroundColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="elementBackgroundColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': elementBackgroundColorError}" v-model="session.elementBackgroundColor"> 
                <color-picker v-model:pureColor="session.elementBackgroundColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />
              </div>
              <div v-if="elementBackgroundColorError" class="invalid-feedback">
                {{elementBackgroundColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="elementBackgroundActiveColor">{{ $t("session.new.sessionElementBackgroundActiveColorLabel") }}</label>

              <div class="d-flex color-comp">
                <input id="elementBackgroundActiveColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': elementBackgroundActiveColorError}" v-model="session.elementBackgroundActiveColor">   
                <color-picker v-model:pureColor="session.elementBackgroundActiveColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />  
              </div>
              <div v-if="elementBackgroundActiveColorError" class="invalid-feedback">
                {{elementBackgroundActiveColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="navbarTextColor">{{ $t("session.new.sessionNavbarTextColorLabel") }}</label>
              
              <div class="d-flex color-comp">
                <input id="navbarTextColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': navbarTextColorError}" v-model="session.navbarTextColor">  
                <color-picker v-model:pureColor="session.navbarTextColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />  
              </div> 
              <div v-if="navbarTextColorError" class="invalid-feedback">
                {{navbarTextColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="navbarBackgroundColor">{{ $t("session.new.sessionNavbarBackgroundColorLabel") }}</label>
              
              <div class="d-flex color-comp">
                <input id="navbarBackgroundColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': navbarBackgroundColorError}" v-model="session.navbarBackgroundColor">  
                <color-picker v-model:pureColor="session.navbarBackgroundColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />  
              </div>
              <div v-if="navbarBackgroundColorError" class="invalid-feedback">
                {{navbarBackgroundColorError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="chatIconColor">{{ $t("session.new.sessionChatIconColorLabel") }}</label>
              <div class="d-flex color-comp">
                <input id="chatIconColor" type="text" class="form-control me-2" placeholder="#000000" :class="{ 'is-invalid': chatIconColorError}" v-model="session.chatIconColor">  
                <color-picker v-model:pureColor="session.chatIconColor" format="hex" pickerType="chrome" useType="pure" disableHistory="true" disableAlpha="true" />
              </div>
              
              

              <div v-if="chatIconColorError" class="invalid-feedback">
                {{chatIconColorError}}
              </div>   
            </div>
            
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("session.new.closeLabel") }}</button>
          <button type="button" class="btn btn-primary" @click="createSession()">{{ $t("session.new.createLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery';
import moment from 'moment-timezone';

import userService from '../../services/userService';
import sessionService from '../../services/sessionService';
import eventConfig from '@/configs/eventConfig';
import sessionConfig from '@/configs/sessionConfig';

import { ColorPicker } from "vue3-colorpicker";

var emptySession = {
      name: null,
      slug: null,
      enabled: true,
      startDate: null,
      endDate: null,
      signin: sessionConfig.SIGNIN_EMAIL,
      qasActivated: true,
      inprogressAccepted: true,
      logo: null,
      icon: null,
      textColor: "#000000",
      helpColor: "#999999",
      elementTextColor: "#000000",
      elementTextActiveColor: "#FFFFFF",
      elementBackgroundColor: "#CCCCCC",
      elementBackgroundActiveColor: "#000000",
      navbarTextColor: "#FFFFFF",
      navbarBackgroundColor: "#000000",
      chatIconColor: "#FFFFFF",
      language: process.env.VUE_APP_I18N_FALLBACK_LOCALE
     };

export default {
  name: 'SessionNewComponent',
  props: {
    action: Boolean
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
        Promise.all([this.loadMe()]).then(() => {
          this.openModal();
        });
      }
    }
  },
  data: function () {
    return {
      session: null,
      newModal: null,
      nameError: null,
      slugError: null,
      slugSuccess: null,
      startDateError: null,
      endDateError: null,
      error: null,
      logoError: null,
      iconError: null,
      textColorError: null,
      helpColorError: null,
      elementTextColorError: null,
      elementTextActiveColorError: null,
      elementBackgroundColorError: null,
      elementBackgroundActiveColorError: null,
      navbarTextColorError: null,
      navbarBackgroundColorError: null,
      chatIconColorError: null,
      languageError: null,
      timeZone: moment.tz.guess()
    }
  },
  components: {
    ColorPicker
  },
  mounted () {
    
    this.newModal = new Modal($('#newSessionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    triggerChanged(c)
    {
      console.log(c)
    },
    loadMe()
    {
      userService.getMe().then(
        response => {
          this.me = response;
          emptySession.language = this.me.language;
          
          this.session = JSON.parse(JSON.stringify(emptySession))

          
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetCurrentSession()
    {
      if(this.session)
      {
        this.session.name = null;
        this.session.slug = null;
        this.session.startDate = null;
        this.session.endDate = null;
        this.session.enabled = true;
        this.session.signin = sessionConfig.SIGNIN_EMAIL;
        this.session.qasActivated = true;
        this.session.inprogressAccepted = true;
        this.session.language = process.env.VUE_APP_I18N_FALLBACK_LOCALE;      
        this.session.textColorError = null;
        this.session.helpColorError = null;
        this.session.elementTextColorError = null;
        this.session.elementTextActiveColorError = null;
        this.session.elementBackgroundColorError = null;
        this.session.elementBackgroundActiveColorError = null;
        this.session.navbarTextColorError = null;
        this.session.navbarBackgroundColorError = null;
        this.session.chatIconColorError = null;
      }
      this.nameError = null;
      this.slugError = null;
      this.slugSuccess = null;
      this.error = null;
      this.languageError = null;
      this.startDateError= null;
      this.endDateError= null;
      this.logoError = null;
      this.iconError = null;
    },
    openModal() {
      this.resetCurrentSession();
      this.newModal.show();
    },
    testSlug()
    {
      this.slugSuccess = null;
      this.slugError = null;
      
      if(this.session.slug != this.currentSlug)
      {
        sessionService.isSlugAvailable(this.session.slug).then(
          response => {
            
            this.slugError = null;
            this.slugSuccess = this.$t(response.msg_code);
            
          },
          errors => {
            
              this.slugSuccess = null;
              this.parseErrors(errors.response.data);
            
          }
        );
      }
    },
    createSession() {
      this.slugSuccess = null;
      this.slugError = null;
      this.nameError = null;
      this.languageError = null;
      this.startDateError= null;
      this.endDateError= null;

      var s = JSON.parse(JSON.stringify(this.session));
      if(this.session.startDate)
      {
        s.startDate = moment(this.session.startDate).format();
      }

      if(this.session.endDate)
      {
        s.endDate = moment(this.session.endDate).format();
      }

      sessionService.create(s).then(
        response => {
          this.newModal.hide();
          this.$emit(eventConfig.CREATED);
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'language':
              this.languageError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'startDate':
              this.startDateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'endDate':
              this.endDateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>