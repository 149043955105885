import apiService from './apiService';

export default class templateGroupService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/templateGroup/list/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getListFull () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/templateGroup/listFull/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

   
    static create (templateGroup) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/templateGroup/new", templateGroup)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (templateGroup) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/templateGroup/edit", templateGroup)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (templateGroup) {
        
        return new Promise((resolve, reject) => {
            apiService.delete("/templateGroup/remove/"+templateGroup.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

}
