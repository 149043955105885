<template>
  <nav class="navbar navbar-light bg-light border-bottom fixed-top fixed-top-second-position">
    <div class="container-fluid">
      <h6><b>{{ $t("session.navbar.title") }}</b></h6>
      <a v-if="superadmin" class="nav-link" @click="$emit(eventConfig.NEW)">
        <img src="@/assets/images/ico-add.svg" alt="Add session">
      </a>
    </div>
  </nav>
</template>

<script>
import eventConfig from '@/configs/eventConfig'


export default {
  name: 'SessionNavbarComponent',
  props: {
    superadmin: Boolean
  },
  data: function () {
    return {
      eventConfig: eventConfig
    }
  }
}
</script>

