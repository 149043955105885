<template>
 <message-404-component></message-404-component>
</template>

<script>
import message404Component from '@/components/404/message404Component.vue'

export default {
  title(){
      return process.env.VUE_APP_TITLE;
  },
  components: { message404Component },
  name: 'Page404View'
}
</script>