<template>
<div id="updateQaModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="qa" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("qas.update.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("qas.update.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="textInput">{{ $t("qas.update.text_label") }}</label>
              <input id="textInput" type="text" class="form-control" :class="{ 'is-invalid': textError}" v-model="qa.text">      
              <div v-if="textError" class="invalid-feedback">
                {{textError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="firstNameInput">{{ $t("qas.update.firstName_label") }}</label>
              <input id="firstNameInput" type="text" class="form-control" :class="{ 'is-invalid': firstNameError}" v-model="qa.firstName">      
              <div v-if="firstNameError" class="invalid-feedback">
                {{firstNameError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="lastNameInput">{{ $t("qas.update.lastName_label") }}</label>
              <input id="lastNameInput" type="text" class="form-control" :class="{ 'is-invalid': lastNameError}" v-model="qa.lastName">      
              <div v-if="lastNameError" class="invalid-feedback">
                {{lastNameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="displayNameUpdate" v-model="qa.displayName">
                <label class="form-check-label" for="displayNameUpdate">{{ $t("qas.update.display_name_label") }}</label>
              </div>
            </div>  
     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("qas.update.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="updateQa()">{{ $t("qas.update.update_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import qaService from '@/services/qaService';


export default {
  name: 'QaUpdateComponent',
  props: {
    qaToUpdate: Object,
    session: Object
  },
  watch: 
  { 
    qaToUpdate: function() {
      if(this.qaToUpdate)
      {
        this.qa = JSON.parse(JSON.stringify(this.qaToUpdate));
        this.resetMessages();
        
        this.openModal();
        
      }
    }
  },
  data: function () {
    return {
      qa: null,
      updateModal: null,
      textError: null,
      firstNameError: null,
      lastNameError: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#updateQaModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.textError = null;
      this.firstNameError = null;
      this.lastNameError = null;
      
      this.error = null;
    },    
    openModal() {

      this.updateModal.show();
    },
    
    updateQa() {
      
      this.resetMessages();

      this.qa.session = this.session.id;

      qaService.update(this.qa).then(
        response => {
          this.updateModal.hide();
          this.$emit(eventConfig.UPDATED, response);
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'text':
              this.textError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'firstName':
              this.firstNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'lastName':
              this.lastNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>