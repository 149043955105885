<template>

<div id="disconnectVoterModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div v-if="voter" class="modal-content">
      <div class="modal-header">
        <h5 v-if="!voter.disconnected" class="modal-title text-warning">{{ $t("voter.disconnect.title") }}</h5>
        <h5 v-if="voter.disconnected" class="modal-title">{{ $t("voter.reconnect.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("voter.disconnect.are_you_sure") }}</p>
        <p v-if="!voter.disconnected" v-html="$t('voter.disconnect.instructions', {firstName: voter.firstName, lastName: voter.lastName})"></p>
        <p v-if="voter.disconnected" v-html="$t('voter.reconnect.instructions', {firstName: voter.firstName, lastName: voter.lastName})"></p>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button v-if="!voter.disconnected" type="button" class="btn btn-danger" @click="disconnectVoter()">{{ $t("voter.disconnect.understand_message") }}</button>
        <button v-if="voter.disconnected" type="button" class="btn btn-danger" @click="reconnectVoter()">{{ $t("voter.reconnect.understand_message") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("voter.disconnect.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import voterService from '@/services/voterService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'VoterDisconnectComponent',
  props: {
    voterToDisconnect: Object
  },
  watch: 
  { 
    voterToDisconnect: function() {
      if(this.voterToDisconnect)
      {
        this.voter = JSON.parse(JSON.stringify(this.voterToDisconnect));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      voter: null,
      error: null,
      disconnectModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.disconnectModal = new Modal($('#disconnectVoterModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.disconnectModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.error = null;
      this.disconnectModal.show();
    },
    disconnectVoter()
    {
      
        voterService.disconnect(this.voter).then(
          response => {
            this.disconnectModal.hide();
            this.$emit(eventConfig.DISCONNECTED);
          },
          errors => {
            console.log(errors);
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    reconnectVoter()
    {
      
        voterService.reconnect(this.voter).then(
          response => {
            this.disconnectModal.hide();
            this.$emit(eventConfig.DISCONNECTED);
          },
          errors => {
            console.log(errors);
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      
    }
  }
}
</script>