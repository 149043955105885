<template>
<div id="updateGroupModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="group" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("group.update.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("group.update.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("group.update.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="group.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("group.update.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="updateGroup()">{{ $t("group.update.update_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import groupService from '@/services/groupService';

export default {
  name: 'GroupUpdateComponent',
  props: {
    groupToUpdate: Object,
    session: Object
  },
  watch: 
  { 
    groupToUpdate: function() {
      if(this.groupToUpdate)
      {
        this.group = JSON.parse(JSON.stringify(this.groupToUpdate));
        this.resetMessages();
        
        this.openModal();
        
      }
    }
  },
  data: function () {
    return {
      group: null,
      updateModal: null,
      nameError: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#updateGroupModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.nameError = null;
      
      this.error = null;
    },    
    openModal() {

      this.updateModal.show();
    },
    
    updateGroup() {
      
      this.resetMessages();

      this.group.session = this.session.id;
      
      groupService.update(this.group).then(
        response => {
          this.updateModal.hide();
          this.$emit(eventConfig.UPDATED, response);
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>