<template>

  <div id="importVotersModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("votersgroups.import.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p class="me-2" v-html="$t('votersgroups.import.instructions')"></p>
          <p class="me-5"><a href="/csv/sample.csv" target="_blank">{{ $t('votersgroups.import.exampleFile') }}</a></p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="inputFile">{{ $t("votersgroups.import.inputfile_label") }}</label>
              <input type="file" ref="file" class="form-control" id="inputFile"  @click="resetFile" @change="csvChange">
            </div>

            <div class="form-group mb-5">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="removeFirstLine" v-model="removeFirstLine">
                <label class="form-check-label" for="removeFirstLine">{{ $t("votersgroups.import.remove_first_line") }}</label>
              </div>
            </div> 

            <p class="me-3">
              {{ $t("votersgroups.import.check_properties") }}
            </p>
            <table>
              <thead>
                <tr>
                  <th scope="col">{{ $t("votersgroups.import.externalId_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.email_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.firstName_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.lastName_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.weight_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.groups_label") }}</th>
                  <th scope="col">{{ $t("votersgroups.import.language_label") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td scope="row">
                    <input disabled type="text" class="form-control" v-model="csv.externalIdCol">
                    <small>{{ firstRow.a }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.emailCol">
                    <small>{{ firstRow.b }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.firstNameCol">
                    <small>{{ firstRow.c }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.lastNameCol">
                    <small>{{ firstRow.d }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.weightCol">
                    <small>{{ firstRow.e }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.groupsCol">
                    <small>{{ firstRow.f }}</small>
                  </td>
                  <td>
                    <input disabled type="text" class="form-control" v-model="csv.languageCol">
                    <small>{{ firstRow.g }}</small>
                  </td>
                </tr>
              </tbody>
            </table>

            <div v-if="session && session.signin != 'EXTERNALID'">
              <div v-if="channels" class="form-group mb-3">
                <label for="channel">{{ $t("votersgroups.import.channel_redirect") }}</label><br>
                <small>{{ $t("votersgroups.import.channel_redirect_help") }}</small>
                <select class="form-select form-select-sm"  v-model="channelSlug">
                  <option v-for="channel in channels" v-bind:key="channel.id" v-bind:value="channel.slug">{{ channel.name }}</option>
                </select>  
              </div>
            </div>
     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("votersgroups.import.close_label") }}</button>
          <button class="btn btn-primary" type="button" :disabled="importInProgress" @click="importVoters()">
            <span v-if="importInProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{ $t("votersgroups.import.import_label") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import voterService from '@/services/voterService';
import channelService from '@/services/channelService';

const emptyCsv = {
       file: null,
       externalIdCol: 'A',
       emailCol: 'B',
       firstNameCol: 'C',
       lastNameCol: 'D',
       weightCol: 'E',
       groupsCol: 'F',
       languageCol: 'G'
     };

export default {
  name: 'ImportVotersComponent',
  props: {
    action: Boolean,
    session: Object
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
        Promise.all([this.loadChannels()]).then(() => {
          this.openModal();
        });
      }
    }
  },
  data: function () {
    return {
      importInProgress: false,
      newModal: null,
      error: null,
      removeFirstLine: true,
      csv: emptyCsv,
      file: null,
      channels: [],
      channelSlug: null,
      firstRow: {
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        f: "",
        g: ""
      }
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#importVotersModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    loadChannels() {  

      channelService.getList(this.session).then(
        response => {
          

          this.channels = response.channels;

          if(this.channels && !this.channelSlug)
          {
            if(this.channels[0])
            {
              this.channelSlug = this.channels[0].slug;
            }
            
          }
          
          
        },
        errors => {
          
        }
      );
    },
    resetFile() {
      this.$refs.file.value = '';
    },
    csvChange(event) {
      var data = {
        session: this.session.id,
        removeFirstLine: this.removeFirstLine
      }
      
      this.file = event.target.files[0];
      voterService.previewCsv(this.file, data).then(
        response => {
          
          var i = 0;
          for (const [key, value] of Object.entries(response)) {
            switch(i)
            {
              case 0:
                this.firstRow.a = value;
              break;
              case 1:
                this.firstRow.b = value;
              break;
              case 2:
                this.firstRow.c = value;
              break;
              case 3:
                this.firstRow.d = value;
              break;
              case 4:
                this.firstRow.e = value;
              break;
              case 5:
                this.firstRow.f = value;
              break;
              case 6:
                this.firstRow.g = value;
              break;
            }
            i++;
          }
        },
        errors => {
          
            this.parseErrors(errors.response.data);
        }
      );

    },
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      this.$refs.file.value = '';
      this.error = null;
      this.file = null;
      this.removeFirstLine = true;
      this.firstRow = {
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        f: "",
        g: ""
      };

      this.newModal.show();
    },
    controlForm() {
      if(this.file)
      {
        return true;
      }else
      {
        this.error = this.$t("votersgroups.import.must_choose_file");
        return false;
      }
    },
    importVoters() {
      
      if(this.controlForm())
      {

        this.error = null;
        
        var data = {
          session: this.session.id,
          removeFirstLine: this.removeFirstLine,
          channelSlug: this.channelSlug
        }
        this.importInProgress = true;

        voterService.importCsv(this.file, data).then(
          response => {
            this.importInProgress = false;
            this.newModal.hide();
            this.$emit(eventConfig.IMPORTED, response);
          },
          errors => {
              this.parseErrors(errors.response.data);
              this.importInProgress = false;
          }
        );
      }
      
    },
    parseErrors(data)
    {
        if(data)
        {
          this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
        }else
        {
          this.error = this.$t("votersgroups.import.error");
        }
        
    
    }
  }
}
</script>
<style scoped>
small {
  display: inline-block;
  font-style: italic;
  color: grey;

}
td small {

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>