<template>

<div id="deleteQuestionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("question.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <p>{{ $t("question.delete.absolutely_sure") }}</p>
        <p v-if="question" v-html="$t('question.delete.action', {text: question.text})"></p>
        
        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeQuestion()">{{ $t("question.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("question.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import questionService from '@/services/questionService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'QuestionDeleteComponent',
  props: {
    questionToDelete: Object,
    session: Object
  },
  watch: 
  { 
    questionToDelete: function() {
      if(this.questionToDelete)
      {
        this.question = JSON.parse(JSON.stringify(this.questionToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      question: null,
      error: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteQuestionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.error = null;
      this.deleteModal.show();
    },
    removeQuestion()
    {
        const data = {
          id: this.question.id,
          session: this.session.id
        };
        questionService.delete(data).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
        this.error = data.msg;
      
    }
  }
}
</script>