<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span><button v-if="pilotStreamAllowed" class="ms-1 me-1 btn btn-light" @click="changeSocket()" :class="{'active': (this.currentDocumentSocket && this.currentDocumentSocket.documentType == documentSocketType.QAS)}"><span class="icon cast"></span> {{ $t("qas.list.cast_label") }}</button></span>
        <a v-if="managingQaAllowed" class="nav-link mb-2" @click="createQa()">
            <img src="@/assets/images/ico-add.svg" alt="Add Q&A">
        </a>
      </div>
  </nav>
  <div id="qas" class="qas">
    <div class="qa ps-3 pe-3 pt-2 pb-2" v-for="qa in qas" v-bind:key="qa.id" :data-id="qa.id">
      <div class="q mt-2 mb-2">
        <span v-if="qa.displayName" class="initial">{{ initialsOf(qa) }}</span><span v-if="!qa.displayName" class="anonymous"></span> {{ qa.text }}
      </div>
      <div class="d-flex justify-content-between">
        <div class="actions actions-crud me-1">
          <a v-if="managingQaAllowed" class="edit" @click="updateQa(qa)"></a>
          <a v-if="managingQaAllowed" class="delete" @click="deleteQa(qa)"></a>
        </div>
        
        <div class="actions actions-pilot">
          <button v-if="pilotStreamAllowed" class="ms-1 me-1 btn btn-light" :class="{'active': qa.visible}" @click="toggleDisplay(qa)"><span class="icon show"></span> {{ $t("qas.list.show_label") }}</button>
        </div>
        
      </div>
    </div>


  </div>

  <qa-new-component @created="createdQa" @canceled="createQaCanceled" :session="session" :action="newAction" />
  <qa-update-component @updated="updatedQa" @canceled="updateQaCanceled" :session="session" :qaToUpdate="qaToUpdate" />
  <qa-delete-component @deleted="deletedQa" @canceled="deleteQaCanceled" :session="session" :qaToDelete="qaToDelete" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import qaService from '@/services/qaService';
import eventConfig from '@/configs/eventConfig';

import QaNewComponent from '../qa/QaNewComponent.vue';
import QaUpdateComponent from '../qa/QaUpdateComponent.vue';
import QaDeleteComponent from '../qa/QaDeleteComponent.vue';
import QasSocket from '@/model/socket/QasSocket';
import DocumentSocketType from '@/model/socket/DocumentSocketType';

import templateService from '@/services/templateService';

export default {
  name: 'QasComponent',
  emits: [eventConfig.SOCKET_CHANGE],
  props: {
    session: Object,
    superadmin: Boolean,
    stream: Object
  },
  data: function () {
    return {
      qas: null,
      eventConfig: eventConfig,
      documentSocketType: DocumentSocketType,
      newAction: false,
      qaToUpdate: null,
      qaToDelete: null,
      currentDocumentSocket: null,
      changeSocketOnReload: false,
      templates: null,
      managingQaAllowed: false,
      readingQaAllowed: false,
      pilotStreamAllowed: false,
    }
  },
  components: {
    'qa-new-component': QaNewComponent,
    'qa-update-component': QaUpdateComponent,
    'qa-delete-component': QaDeleteComponent
  },
  mounted () {

    this.currentDocumentSocket = this.stream.documentSocket;
    
    setTimeout(() => {
      this.resize();  
    }, 300);

    $(window).resize(()=>{
      $(".qas").css('max-height', "0px" );
      this.resize();
    });


    this.loadTemplates();
    this.eventDispatcher.on(eventConfig.RELOAD_QAS, () => {
      this.loadQas()
    });

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_QAS)) )
    {
      this.managingQaAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_QAS)) )
    {
      this.readingQaAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_STREAM)) )
    {
      this.pilotStreamAllowed = true;
    }

  },
  methods: 
  {
    initialsOf(qa) {
      if(qa.firstName && qa.lastName)
      {
        return qa.firstName.charAt(0).toUpperCase()+qa.lastName.charAt(0).toUpperCase();
      }
      return "";
    },
    resize() {
      var streamContainer = $("#streamContainer").height();
      $(".qas").css('max-height', (streamContainer-64)+"px" );
    },
    loadTemplates() { 
      templateService.getListQas().then(
        response => {
          this.templates = response;
          this.loadQas();
        },
        errors => {
          
        }
      );
    },
    loadQas() {  
      
      qaService.getList(this.session).then(
        response => {
         
          this.qas = response;

          if(this.changeSocketOnReload && (this.currentDocumentSocket && this.currentDocumentSocket.documentType == this.documentSocketType.QAS))
          {
            this.changeSocket();
            this.changeSocketOnReload = false;
          }
          
          if(this.managingQaAllowed)
          {
            $("#qas").sortable({
              update: () => {
                var orderedQasId = [];
                  $("#qas .qa").each((i,elem)=>{
                    if($(elem).attr('data-id') != undefined)
                    {
                      orderedQasId.push( $(elem).attr('data-id') );
                    }
                  });

                this.updateQasRank(orderedQasId);
                
              }
            });
          }
          
          
        },
        errors => {
          
        }
      );
    },
    updateQasRank(orderedQasId)
    {
      
      qaService.reorder(orderedQasId).then(
        response => {
          this.changeSocketOnReload = true;
          this.loadQas();
          
        },
        errors => {
          
        }
      );
    },
    createQa()
    {
      this.newAction = true;
    },
    createdQa(qa)
    {
      this.newAction = false;
      this.changeSocketOnReload = true;
      this.loadQas();
    },
    createQaCanceled()
    {
      this.newAction = false;
    },
   
    updateQa(qa)
    {
      this.qaToUpdate = qa;
    },
    updatedQa(qa)
    {
      
      this.qaToUpdate = null;
      this.changeSocketOnReload = true;
      this.loadQas();
    },
    updateQaCanceled()
    {
      this.qaToUpdate = null;
    },    
    
    deleteQa(qa)
    {
      this.qaToDelete = qa;
    },
    deleteQaCanceled()
    {
      this.qaToDelete = null;
    },
    deletedQa()
    {
      this.qaToDelete = null;
      this.changeSocketOnReload = true;
      this.loadQas();
    },
    toggleDisplay(qa)
    {
      
      const data = {
        id: qa.id,
        session: this.session.id,
        visible: !qa.visible
      }
      qaService.updateDisplay(data).then(
        response => {
          
          if(this.currentDocumentSocket && this.currentDocumentSocket.documentType == this.documentSocketType.QAS)
          {
            this.changeSocketOnReload = true;
          }
          this.loadQas();
        },
        errors => {
          
        }
      );

    },
    changeSocket()
    {
      var template = null;
      var streamData = JSON.parse(JSON.stringify(this.stream)).data;
      for(const t of this.templates)
      {
        if(t.id == this.stream.qasTemplate)
        {
          template = t;
          break;
        }
      }
      template.data = streamData;
      let doc = new QasSocket(null, {template: template, qas: this.qas});

      this.$emit(eventConfig.SOCKET_CHANGE, doc);
      
    }
  }
 
}
</script>

<style scoped lang="scss">

.qas {
  max-height: 0px;
  overflow-y: scroll;
}

.qa.active {
  background-color: #E0E0E0;
}

.q {
  line-height: 35px;
}


.initial, .anonymous {
   width: 32px;
   height: 32px;
   line-height: 32px;
   text-align: center;
   background-color: black;
   color: white;
   display: inline-block;
   border-radius: 16px;
   font-size: 0.9em;
  margin-right: 10px;
 }

 .anonymous {
     background-image: url(@/assets/images/ico-user-nav.svg);
     background-repeat: no-repeat;
     background-position: center center;
 }


.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}


.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

.btn.btn-light {
  border: 1px solid black;
}

.btn .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 6px;
}


.btn.active {
  background-color: black;
  color: white;
}

.btn .icon.show {
  background-image: url(@/assets/images/ico-show.svg);
}
.btn.active .icon.show {
  background-image: url(@/assets/images/ico-show-on.svg);
}

.btn .icon.cast {
  background-image: url(@/assets/images/ico-cast.svg);
}
.btn.active .icon.cast {
  background-image: url(@/assets/images/ico-cast-on.svg);
}
</style>