<template>
  <div v-if="readingChannelAllowed" class="container-fluid p-2 border-bottom channels">
    <div class="channel d-flex" v-for="channel in channels" v-bind:key="channel.id">
      <div class="name me-auto">{{ channel.name }}<br>({{ channel.slug }})</div>
      <div class="streams">
        <select class="form-select form-select-sm"  :disabled="!pilotChannelAllowed" v-model="channel.stream" @change="selectStream(channel)">
          <option v-for="stream in streams" v-bind:key="stream.id" v-bind:value="stream.id">{{ stream.name }}</option>
        </select>
      </div>
      <div class="actions">
        <a v-if="readingChannelAllowed" class="copy" tabindex="_blank" v-bind:href="publicURL+'/'+session.slug+'/'+channel.slug" target="_blank">
          <span>Open</span>
        </a>
        <a v-if="managingChannelAllowed" class="edit" @click="$emit(eventConfig.UPDATE, channel)">
          <span>Edit</span>
        </a>
        <a v-if="managingChannelAllowed" class="delete" @click="$emit(eventConfig.DELETE, channel)">
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import channelService from '@/services/channelService';
import privilegeConfig from '@/configs/privilegeConfig';
import eventConfig from '@/configs/eventConfig';


export default {
  name: 'ChannelListComponent',
  props: {
    superadmin: Boolean,
    session: Object
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
        this.loadChannels();
        this.setPrivileges();
      }
    }
  },
  data: function () {
    return {
      channels: null,
      streams: null,
      eventConfig: eventConfig,
      managingChannelAllowed: false,
      readingChannelAllowed: false,
      pilotChannelAllowed: false,
      publicURL: process.env.VUE_APP_PUBLIC_URL

    }
  },
  components: {
    
  },
  mounted () {
    this.loadChannels();
    this.eventDispatcher.on(eventConfig.RELOAD_CHANNELS, () => {
      this.loadChannels();
    });

    this.eventDispatcher.on(eventConfig.RELOAD_STREAMS, ()=>{
      this.loadChannels();
    });

    

  },
  methods: {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_CHANNEL)) )
      {
        this.managingChannelAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_CHANNEL)) )
      {
        this.readingChannelAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_CHANNEL)) )
      {
        this.pilotChannelAllowed = true;
      }
    },
    selectStream(channel)
    {
      
      channelService.updateChannelStream(channel).then(
        response => {
          
          this.eventDispatcher.emit(eventConfig.CHANNEL_CHANGE, {channel: channel} );
        },
        errors => {
          
        }
      );
    },
    loadChannels() {  

      

      channelService.getList(this.session).then(
        response => {
          
          this.streams = response.streams;
          this.channels = response.channels;

          
        },
        errors => {
          
        }
      );
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';


.channels {
  max-height: $list-height*2;
  overflow-y: scroll;
}

.channel {
  padding-top: 5px;
  padding-bottom: 5px;
}

.name {
  line-height: 18px;
  font-size: 0.9em;
}

.streams select {
  height: 40px;
  font-size: 0.9em;
  width: 110px;
  margin-right: 10px;
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.actions a span {
  display: none;
}

.actions a.copy {
  background-image: url(@/assets/images/ico-copy-url.svg);
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}


.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>