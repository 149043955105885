const DocumentSocket = require("./DocumentSocket");
const DocumentSocketType = require("./DocumentSocketType");

class QasSocket extends DocumentSocket {
    constructor(documentId, qas) {
        
        super(documentId, DocumentSocketType.QAS, null, qas);
    }

}
module.exports = QasSocket;