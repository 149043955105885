<template>
<div id="updateChannelModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("channel.update.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("channel.update.instructions") }}</p>
        <form @submit.prevent="">
          
          <div class="form-group mb-3">
            <label for="nameInput">{{ $t("channel.update.name_label") }}</label>
            <input v-if="channel" id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="channel.name">      
            <div v-if="nameError" class="invalid-feedback">
              {{nameError}}
            </div>   
          </div>
          <div class="form-group mb-3">
            <label for="slugInput">{{ $t("channel.update.slug_label") }}</label>
            <input v-if="channel" type="text" class="form-control" :class="{ 'is-invalid': slugError, 'is-valid': slugSuccess }" id="slugInput" v-model="channel.slug" @input="testSlug()">
            <div v-if="slugError" class="invalid-feedback">
              {{slugError}}
            </div>
            <div v-if="slugSuccess" class="valid-feedback">
              {{slugSuccess}}
            </div>
          </div>
          
          <div v-if="error" class="alert alert-danger" role="alert">
            {{error}}
          </div>
          
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("channel.update.close_label") }}</button>
        <button type="button" class="btn btn-primary" @click="updateChannel()">{{ $t("channel.update.update_label") }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import channelService from '@/services/channelService';

export default {
  name: 'ChannelUpdateComponent',
  props: {
    channelToUpdate: Object,
    session: Object
  },
  watch: 
  { 
    channelToUpdate: function() {
      if(this.channelToUpdate)
      {
        this.channel = JSON.parse(JSON.stringify(this.channelToUpdate));
        this.currentSlug = this.channel.slug;
        this.resetMessages();
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      channel: null,
      updateModal: null,
      nameError: null,
      slugError: null,
      currentSlug: null,
      slugSuccess: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#updateChannelModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.nameError = null;
      this.slugError = null;
      this.slugSuccess = null;
      this.error = null;
    },    
    openModal() {
      this.updateModal.show();
    },
    testSlug()
    {
      this.slugSuccess = null;
      this.slugError = null;

      if(this.channel.slug != this.currentSlug)
      {
        
        channelService.isSlugAvailable(this.channel.slug, this.session).then(
          response => {
            this.slugSuccess = (response.msg_code) ? this.$t(response.msg_code) : response.msg;
            this.slugError = null;
          },
          errors => {
            this.slugSuccess = null;
            this.parseErrors(errors.response.data);
          }
        );
      }
    },
    updateChannel() {
      this.slugSuccess = null;
      this.slugError = null;
      this.nameError = null;

      this.channel.session = this.session.id;
      channelService.update(this.channel).then(
        response => {
          this.updateModal.hide();
          this.$emit(eventConfig.UPDATED);
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>