<template>

  <div id="answersQuestionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("question.answers.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
          
        </div>
        <nav class="navbar navbar-light bg-light border-bottom">
            <div class="container-fluid ps-2 pe-0">
              <span></span>
              <a v-if="(!alertVotesExist || acceptEraseVote)" class="nav-link mb-2" @click="createAnswer()">
                  <img src="@/assets/images/ico-add.svg" alt="Add answer">
              </a>
            </div>
          </nav>
        <div class="modal-body">

          <div v-if="alertVotesExist" class="alert alert-danger" role="alert">
            <p v-html="$t('question.answers.caution_accept_erase_votes')"></p>
            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="externalIdSignin" v-model="acceptEraseVote">
                <label class="form-check-label" for="externalIdSignin" v-html="$t('question.answers.accept_erase_votes')"></label>
              </div>
            </div>
          </div>
          
            <div v-if="question" id="sortable-answers">
              <div class="answer form-group p-3 pb-0 " v-for="answer in answers" v-bind:key="answer.id" :data-id="answer.id">
                <input v-bind:disabled="(alertVotesExist && !acceptEraseVote)" id="'textInput'+answer.id" type="text" class="form-control" v-model="answer.text" :placeholder="$t('question.answers.text_label')">
                <div class="row">
                  <div class="col-2 offset-7 mt-2">
                    <input v-bind:disabled="(alertVotesExist && !acceptEraseVote)" id="'prevoteInput'+answer.id" type="number" step="1" min="0" class="form-control" v-model="answer.prevotes" placeholder="Prevotes">
                  </div>
                  <div class="col-2 pt-3">{{ $t("question.answers.prevotes") }}</div>
                  <div class="col-1 actions actions-crud text-end">
                    <a v-if="(!alertVotesExist || acceptEraseVote)" class="delete mt-2" @click="deleteAnswer(answer)"></a>
                  </div>
                </div>
                
              </div>
            </div>
     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>




            
            
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("question.answers.close_label") }}</button>
          <button v-bind:disabled="(alertVotesExist && !acceptEraseVote)" type="button" class="btn btn-primary" @click="updateAnswers()">{{ $t("question.answers.update_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import eventConfig from '@/configs/eventConfig';
import answerService from '@/services/answerService';



export default {
  name: 'QuestionAnswersComponent',
  props: {
    questionToManageAnswers: Object,
    session: Object
  },
  watch: 
  { 
    questionToManageAnswers: function() {
      if(this.questionToManageAnswers)
      {
        this.question = JSON.parse(JSON.stringify(this.questionToManageAnswers));
        
        Promise.all([this.loadAnswers(), this.checkIfQuestionHaveNotExternalVotes()]).then(() => {
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      question: null,
      answers: null,
      error: null,
      alertVotesExist: false,
      acceptEraseVote: false
    }
  },
  components: {
    
  },
  mounted () {
    
    this.manageModal = new Modal($('#answersQuestionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {

    loadAnswers()
    {
      var data = {
        question: this.question.id,
        session: this.session.id
      }
      answerService.getListByQuestion(data).then(
        response => {
          this.answers = response;
          
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    checkIfQuestionHaveNotExternalVotes()
    {
      var data = {
        question: this.question.id,
        session: this.session.id
      }
      answerService.checkIfQuestionHaveNotExternalVotes(data).then(
        response => {
          
          if(response.result == true)
          {
            this.alertVotesExist = true;
          }else
          {
            this.alertVotesExist = false;
          }
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    openModal() {
      this.error = null;
      this.manageModal.show();
      
      $("#sortable-answers").sortable({
        update: () => {
          var orderedAnswersId = [];
            $("#sortable-answers .answer").each((i,elem)=>{
              if($(elem).attr('data-id') != undefined)
              {
                orderedAnswersId.push( $(elem).attr('data-id') );
              }
            });

          this.updateAnswersRank(orderedAnswersId);
          
        }
      });
    },
    createAnswer() {
      
      if(!this.answers)
      {
        this.answers = [];
      }

      this.answers.push(
        {
          text: '',
          id: 'id'+this.answers.length,
          rank: this.answers.length,
          question: this.question.id,
          prevotes: 0
        }
      )
    },
    deleteAnswer(answer)
    {
      for(var i=0; i<this.answers.length; i++)
      {
        if(answer == this.answers[i])
        {
          this.answers.splice(i, 1);
        }
      }
    },
    updateAnswers()
    {

      const data = {
          answers: this.answers,
          session: this.session.id
      }
      answerService.updateAnswers(data).then(
        response => {
          this.manageModal.hide();
          this.$emit(eventConfig.UPDATED, response);
        },
        errors => {
          console.log(errors);
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
       if(data.errors)
      {
        for(let error of data.errors)
        {
          this.error = error.msg;
        }
      }else
      {
        this.error = data.msg;
      }
      
    },
    cancel()
    {
      this.manageModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    updateAnswersRank(orderedAnswersId)
    {
      
      for(var i=0; i<orderedAnswersId.length; i++)
      {
        for(var j=0; j<this.answers.length; j++)
        {
          if(this.answers[j].id == orderedAnswersId[i])
          {
            this.answers[j].rank = i;
          }
        }
      }
      
    },
  }
}
</script>
<style scoped>

.pair {
  background-color: #E0E0E0;
}

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

</style>