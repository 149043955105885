<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a v-if="managingVoterAllowed" class="nav-link mb-2" @click="createVoter()">
            <img src="@/assets/images/ico-add.svg" alt="Add screen">
        </a>
      </div>
  </nav>
  <div v-if="readingVoterAllowed" class="row m-0 flex-grow-1">
    <div class="col col-12 p-0">
      <table class="table">
        <thead>
          <tr>
            <th v-if="session.signin == 'EXTERNALID'" scope="col">{{ $t("voter.list.externalId_label") }}</th>
            <th scope="col">{{ $t("voter.list.email_label") }}</th>
            <th scope="col">{{ $t("voter.list.firstName_label") }}</th>
            <th scope="col">{{ $t("voter.list.lastName_label") }}</th>
            <th scope="col">{{ $t("voter.list.vote_weight_label") }}</th>
            <th scope="col">{{ $t("voter.list.groups_label") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="voter in voters" v-bind:key="voter.id">
            <th v-if="session.signin == 'EXTERNALID'" scope="row">{{voter.externalId}}</th>
            <td>{{voter.email}}</td>
            <td>{{voter.firstName}}</td>
            <td>{{voter.lastName}}</td>
            <td>x{{voter.weight}}</td>
            <td>
              <span v-for="group in voter.groups" v-bind:key="group.id">{{group.name+' '}} </span>
            </td>
            <td class="actions me-2 text-end">
                <a v-if="managingVoterAllowed" class="disconnect" :class="{'on': voter.disconnected}" @click="disconnectVoter(voter)"></a>
                <a v-if="managingVoterAllowed" class="ban" :class="{'on': voter.banned}" @click="banVoter(voter)"></a>
                <a v-if="managingVoterAllowed" class="edit" @click="updateVoter(voter)"></a>
                <a v-if="managingVoterAllowed" class="delete" @click="deleteVoter(voter)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    
  </div>

  <voter-new-component @created="createdVoter" @canceled="createVoterCanceled" :session="session" :action="newAction" />
  <voter-update-component @updated="updatedVoter" @canceled="updateVoterCanceled" :session="session" :voterToUpdate="voterToUpdate" />
  <voter-delete-component @deleted="deletedVoter" @canceled="deleteVoterCanceled" :session="session" :voterToDelete="voterToDelete" />

  <voter-disconnect-component @disconnected="disconnectedVoter" @canceled="disconnectVoterCanceled" :voterToDisconnect="voterToDisconnect" />
  <voter-ban-component @banned="bannedVoter" @canceled="banVoterCanceled" :voterToBan="voterToBan" />  

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import voterService from '@/services/voterService';
import eventConfig from '@/configs/eventConfig';

import VoterNewComponent from '../voter/VoterNewComponent.vue'
import VoterUpdateComponent from '../voter/VoterUpdateComponent.vue'
import VoterDeleteComponent from '../voter/VoterDeleteComponent.vue'

import VoterDisconnectComponent from '../voter/VoterDisconnectComponent.vue'
import VoterBanComponent from '../voter/VoterBanComponent.vue'


export default {
  name: 'VotersComponent',
  emits: [eventConfig.LOADED],
  props: {
    session: Object,
    superadmin: Boolean,
    loadAction: Boolean
  },
  data: function () {
    return {
      voters: null,
      eventConfig: eventConfig,
      newAction: false,
      voterToUpdate: null,
      voterToDelete: null,
      voterToDisconnect: null,
      voterToBan: null,
      readingVoterAllowed: false,
      managingVoterAllowed: false
    }
  },
  components: {
    'voter-new-component': VoterNewComponent,
    'voter-update-component': VoterUpdateComponent,
    'voter-delete-component': VoterDeleteComponent,
    'voter-disconnect-component': VoterDisconnectComponent,
    'voter-ban-component': VoterBanComponent
  },
  watch: 
  { 
    loadAction: function() {
      if(this.loadAction)
      {
        this.loadVoters();
      }
    },
    session: function() {
      if(this.session)
      {
        this.loadVoters();
        this.setPrivileges();
      }
    }
  },
  mounted () {
    this.loadVoters();
    this.setPrivileges();
  },
  methods: 
  {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_VOTER)) )
      {
        this.readingVoterAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_VOTER)) )
      {
        this.managingVoterAllowed = true;
      }

    },
    loadVoters() {  
      
      voterService.getList(this.session).then(
        response => {
          
          this.voters = response;
          this.$emit(eventConfig.LOADED);
        },
        errors => {
          
        }
      );
    },
    
    createVoter()
    {
      this.newAction = true;
    },
    createdVoter(voter)
    {
      this.newAction = false;
      this.loadVoters();
    },
    createVoterCanceled()
    {
      this.newAction = false;
    },
   
    updateVoter(voter)
    {
      this.voterToUpdate = voter;
    },
    updatedVoter(voter)
    {
      
      this.voterToUpdate = null;
      this.loadVoters();
    },
    updateVoterCanceled()
    {
      this.voterToUpdate = null;
    },    
  
    deleteVoter(voter)
    {
      this.voterToDelete = voter;
    },
    deleteVoterCanceled()
    {
      this.voterToDelete = null;
    },
    deletedVoter()
    {
      this.voterToDelete = null;
      this.loadVoters();
    },

    disconnectVoter(voter)
    {
      this.voterToDisconnect = voter;
    },
    disconnectVoterCanceled()
    {
      this.voterToDisconnect = null;
    },
    disconnectedVoter()
    {
      this.voterToDisconnect = null;
      this.loadVoters();
    },

    banVoter(voter)
    {
      this.voterToBan = voter;
    },
    banVoterCanceled()
    {
      this.voterToBan = null;
    },
    bannedVoter()
    {
      this.voterToban = null;
      this.loadVoters();
    }
  }
 
}
</script>

<style scoped lang="scss">

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.actions a.disconnect {
  background-image: url(@/assets/images/ico-user-disconnect.svg);
}
.actions a.disconnect.on {
  background-image: url(@/assets/images/ico-user-disconnect-on.svg);
}
.actions a.ban {
  background-image: url(@/assets/images/ico-user-ban.svg);
}
.actions a.ban.on {
  background-image: url(@/assets/images/ico-user-ban-on.svg);
}
.actions a.groups {
  background-image: url(@/assets/images/ico-group.svg);
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>