class socketConfig {
  static JOIN_SESSION = 'joinSession';
  static PILOT_SESSION = 'pilotSession';
  static JOIN_VOTE = 'joinVote';
  static PILOT_VOTE = 'pilotVote';
  static GET_MY_VOTES = 'getMyVotes';
  static SEND_MY_VOTES = 'sendMyVotes';
  static RELOAD_MY_VOTES = 'reloadMyVotes';
  static GET_VOTER_MESSAGES = 'getVoterMessages';
  static SEND_VOTER_MESSAGES = 'sendVoterMessages';
  static GET_SESSION_MESSAGES = 'sendSessionMessages';
  static SEND_SESSION_MESSAGES = 'sendSessionMessages';
  static SEND_VOTER_MESSAGE = 'sendVoterMessage';
  static SEND_SESSION_MESSAGE = 'sendSessionMessage';
 }

module.exports = socketConfig;