import apiService from './apiService';

export default class screenService {


    static getList (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/screen/listBySession/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static reorder (screenIds) {
        
        return new Promise((resolve, reject) => {
            const data = {
                screenIds: screenIds
            }
            apiService.post("/screen/reorder/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static create (screen) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/screen/new", screen)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (screen) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/screen/edit", screen)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (data) {
        
        return new Promise((resolve, reject) => {
            apiService.post("/screen/remove/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
