<template>

  <div id="passwordLostModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("passwordLost.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("passwordLost.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="emailPasswortLostInput">{{ $t("passwordLost.emailLabel") }}</label>
              <input id="emailPasswortLostInput" type="email" class="form-control" :class="{ 'is-invalid': emailError}" v-model="email">      
              <div v-if="emailError" class="invalid-feedback">
                {{emailError}}
              </div>   
            </div>
     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>

            <div v-if="success" class="alert alert-success" role="alert">
              {{success}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("passwordLost.closeLabel") }}</button>
          <button v-if="!success" type="button" class="btn btn-primary" @click="retreive()">{{ $t("passwordLost.retreiveLabel") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import userService from '@/services/userService';

export default {
  name: 'PasswordLostComponent',
  props: {
    action: Boolean
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {

          this.openModal();
        
      }
    }
  },
  data: function () {
    return {
      email: null,
      passwordLostModal: null,
      emailError: null,
      error: null,
      success: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.passwordLostModal = new Modal($('#passwordLostModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    
    cancel()
    {
      this.email = null;
      this.error = null;
      this.success = null;
      this.emailError = null;

      this.passwordLostModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      
      this.passwordLostModal.show();
    },
    retreive() {
      
      this.emailError = null;
      this.error = null;
      this.success = null;

      var user = {
        email: this.email
      }
      userService.lostPassword(user).then(
        response => {
          console.log(response)
          this.success = (response.msg_code) ? this.$t(response.msg_code) : response.msg;
          
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'email':
              this.emailError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>
<style scoped>
</style>