<template>

<div id="deleteQaModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("qas.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("qas.delete.absolutely_sure") }}</p>
        <p v-if="qa" v-html="$t('qas.delete.action', {text: qa.text})"></p>
        
        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeQa()">{{ $t("qas.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("qas.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import qaService from '@/services/qaService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'QaDeleteComponent',
  props: {
    qaToDelete: Object,
    session: Object
  },
  watch: 
  { 
    qaToDelete: function() {
      if(this.qaToDelete)
      {
        this.qa = JSON.parse(JSON.stringify(this.qaToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      qa: null,
      error: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteQaModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.error = null;
      this.deleteModal.show();
    },
    removeQa()
    {
        const data = {
          id: this.qa.id,
          session: this.session.id
        };
        qaService.delete(data).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      
    }
  }
}
</script>