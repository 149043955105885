<template>

<div id="deleteChannelModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("channel.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("channel.delete.absolutely_sure") }}</p>
        <p v-if="channel" v-html="$t('channel.delete.action', {name: channel.name})"></p>
        <p v-if="channel" v-html="$t('channel.delete.actionName', {code: channel.slug})"></p>

        <div class="form-group mb-3">
            <label for="slugInput">{{ $t("channel.delete.channel_slug") }}</label>
            <input type="text" class="form-control" id="slugInput" v-model="deleteSlug" :class="{ 'is-invalid': deleteSlugError}" @input="deleteSlugError = null" @click.right.prevent @copy.prevent @paste.prevent>
            <div v-if="deleteSlugError" class="invalid-feedback">
              {{deleteSlugError}}
            </div>
        </div>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeChannel()">{{ $t("channel.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("channel.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import channelService from '@/services/channelService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'ChannelDeleteComponent',
  props: {
    channelToDelete: Object,
    session: Object
  },
  watch: 
  { 
    channelToDelete: function() {
      if(this.channelToDelete)
      {
        this.channel = JSON.parse(JSON.stringify(this.channelToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      channel: null,
      error: null,
      currentSlug: null,
      deleteSlug: null,
      deleteSlugError: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteChannelModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.deleteSlug = null;
      this.deleteSlugError = null;
      this.error = null;
      this.deleteModal.show();
    },
    removeChannel()
    {
      if(this.deleteSlug === this.channel.slug)
      {
        channelService.delete(this.channel).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      }else
      {
        this.deleteSlugError = this.$t("channel.delete.slug_doesnt_match");
      }
    },
    parseErrors(data)
    {
      console.log(data);
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>