<template>
<div id="updateQuestionModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="question" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("question.update.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("question.update.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="textInputUpdate">{{ $t("question.update.text_label") }}</label>
              <input id="textInputUpdate" type="text" class="form-control" :class="{ 'is-invalid': textError}" v-model="question.text">      
              <div v-if="textError" class="invalid-feedback">
                {{textError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="minChoiceInputUpdate">{{ $t("question.update.min_choices_label") }}</label>
              <input id="minChoiceInputUpdate" type="number" min="1" step="1" class="form-control" placeholder="Integer min value 1" :class="{ 'is-invalid': minChoiceError}" v-model="question.minChoice">      
              <div v-if="minChoiceError" class="invalid-feedback">
                {{minChoiceError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="maxChoiceInputUpdate">{{ $t("question.update.max_choices_label") }}</label>
              <input id="maxChoiceInputUpdate" type="number" min="1" step="1" placeholder="Integer min value 1" class="form-control" :class="{ 'is-invalid': maxChoiceError}" v-model="question.maxChoice">      
              <div v-if="maxChoiceError" class="invalid-feedback">
                {{maxChoiceError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="weightCountedUpdate" v-model="question.weightCounted">
                <label class="form-check-label" for="weightCountedUpdate">{{ $t("question.update.weight_counted_label") }}</label>
              </div>
            </div>  

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="exprimableUpdate" v-model="question.exprimable">
                <label class="form-check-label" for="exprimableUpdate">{{ $t("question.update.exprimable_label") }}</label>
              </div>
            </div>   
            
            <div v-if="question.exprimable" class="form-group mb-3">
              <label for="labelExprimableInputUpdate">{{ $t("question.update.exprimable_value_label") }}</label>
              <input id="labelExprimableInputUpdate" type="number" min="0" step="1" class="form-control" :class="{ 'is-invalid': exprimableValueError}" v-model="question.exprimableValue">      
              <div v-if="exprimableValueError" class="invalid-feedback">
                {{exprimableValueError}}
              </div>   
            </div>


            <hr class="mb-3">

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="goNextUpdate" v-model="question.goNext">
                <label class="form-check-label" for="goNextUpdate">{{ $t("question.update.go_next_label") }}</label>
              </div>
            </div>
            
            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="previousButtonVisibleUpdate" v-model="question.previousButtonVisible">
                <label class="form-check-label" for="previousButtonVisibleUpdate">{{ $t("question.update.previous_button_visible_label") }}</label>
              </div>
            </div>

            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="nextButtonVisibleUpdate" v-model="question.nextButtonVisible">
                <label class="form-check-label" for="nextButtonVisibleUpdate">{{ $t("question.update.next_button_visible_label") }}</label>
              </div>
            </div>

            <hr class="mb-3">



            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="absenceCountedUpdate" v-model="question.absenceCounted">
                <label class="form-check-label" for="absenceCountedUpdate">{{ $t("question.update.absence_counted_label") }}</label>
              </div>
            </div>  
            

            <div v-if="question.absenceCounted" class="form-group mb-3">
              <label for="labelAbstentionInputUpdate">{{ $t("question.update.label_abstention_label") }}</label>
              <input id="labelAbstentionInputUpdate" type="text" class="form-control" :class="{ 'is-invalid': labelAbstentionError}" v-model="question.labelAbstention">      
              <div v-if="labelAbstentionError" class="invalid-feedback">
                {{labelAbstentionError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label for="templateSelect">{{ $t("question.update.present_template_label") }}</label>
              <select class="form-select form-select-sm" v-model="question.presentTemplate" :class="{ 'is-invalid': presentTemplateError}" @change="presentTemplateChange(question.presentTemplate)">
                <option v-for="presentTemplate in presentTemplates" v-bind:key="presentTemplate.id" v-bind:value="presentTemplate">{{ presentTemplate.name }}</option>
              </select>
              <div v-if="presentTemplateError" class="invalid-feedback">
                {{presentTemplateError}}
              </div>   
            </div>

            <div class="form-group mb-3" v-if="question.presentTemplate">
              <label>{{ $t("question.update.present_template_custom_values_label") }}</label>
              <fieldset class="scheduler-border">
                <div v-for="(property, propertyName) in question.presentTemplate.properties" v-bind:key="property">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'String'">
                    <label class="form-label" :for="propertyName">{{property.help}}</label>
                    <textarea class="form-control" :id="propertyName" rows="3" v-model="question.data.present[propertyName]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'Boolean'">
                    <input class="form-check-input" type="checkbox" :id="propertyName" :checked="property.default" v-model="question.data.present[propertyName]">
                    <label class="form-check-label" :for="propertyName">{{property.help}}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>




            <div class="form-group mb-3">
              <label for="templateSelect">{{ $t("question.update.vote_template_label") }}</label>
              <select class="form-select form-select-sm" v-model="question.voteTemplate" :class="{ 'is-invalid': voteTemplateError}" @change="voteTemplateChange(question.voteTemplate)">
                <option v-for="voteTemplate in voteTemplates" v-bind:key="voteTemplate.id" v-bind:value="voteTemplate">{{ voteTemplate.name }}</option>
              </select>
              <div v-if="voteTemplateError" class="invalid-feedback">
                {{voteTemplateError}}
              </div>   
            </div>

            <div class="form-group mb-3" v-if="question.voteTemplate">
              <label>{{ $t("question.update.vote_template_custom_values_label") }}</label>
              <fieldset class="scheduler-border">
                <div v-for="(property, propertyName) in question.voteTemplate.properties" v-bind:key="property">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'String'">
                    <label class="form-label" :for="propertyName">{{property.help}}</label>
                    <textarea class="form-control" :id="propertyName" rows="3" v-model="question.data.vote[propertyName]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'Boolean'">
                    <input class="form-check-input" type="checkbox" :id="propertyName" :checked="property.default" v-model="question.data.vote[propertyName]">
                    <label class="form-check-label" :for="propertyName">{{property.help}}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>

            <div class="form-group mb-3">
              <label for="templateSelect">{{ $t("question.update.template_result_label") }}</label>
              <select class="form-select form-select-sm" v-model="question.resultTemplate" :class="{ 'is-invalid': resultTemplateError}" @change="resultTemplateChange(question.resultTemplate)">
                <option v-for="resultTemplate in resultTemplates" v-bind:key="resultTemplate.id" v-bind:value="resultTemplate">{{ resultTemplate.name }}</option>
              </select>
              <div v-if="resultTemplateError" class="invalid-feedback">
                {{resultTemplateError}}
              </div>   
            </div>            

            
            <div class="form-group mb-3" v-if="question.resultTemplate">
              <label>{{ $t("question.update.result_template_custom_values_label") }}</label>
              <fieldset class="scheduler-border">
                <div v-for="(property, propertyName) in question.resultTemplate.properties" v-bind:key="property">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'String'">
                    <label class="form-label" :for="propertyName">{{property.help}}</label>
                    <textarea class="form-control" :id="propertyName" rows="3" v-model="question.data.result[propertyName]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'Boolean'">
                    <input class="form-check-input" type="checkbox" :id="propertyName" :checked="property.default" v-model="question.data.result[propertyName]">
                    <label class="form-check-label" :for="propertyName">{{property.help}}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>

            <div class="form-group mb-3">
              <label>{{ $t("question.update.assignated_groups_label") }}</label>
              <fieldset class="scheduler-border">
              
                <div class="form-check" v-for="group in groups" v-bind:key="group.id" >
                  <input class="form-check-input" type="checkbox" :id="'g'+group.id" :value="group.id" v-model="question.groups">
                  <label class="form-check-label" :for="'g'+group.id">
                    {{ group.name }}
                  </label>
                </div>
              </fieldset>
            </div>
     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("question.update.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="updateQuestion()">{{ $t("question.update.update_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import templateService from '@/services/templateService';
import groupService from '@/services/groupService';
import questionService from '@/services/questionService';


export default {
  name: 'QuestionUpdateComponent',
  props: {
    questionToUpdate: Object,
    session: Object
  },
  watch: 
  { 
    questionToUpdate: function() {
      if(this.questionToUpdate)
      {
        this.question = JSON.parse(JSON.stringify(this.questionToUpdate));
        this.resetMessages();
        Promise.all([this.loadVoteTemplates(), this.loadPresentTemplates(), this.loadResultTemplates(), this.loadGroups()]).then(() => {
          this.openModal();
        });
      }
    }
  },
  data: function () {
    return {
      question: null,
      updateModal: null,
      textError: null,
      minChoiceError: null,
      maxChoiceError: null,
      voteTemplateError: null,
      presentTemplateError: null,
      resultTemplateError: null,
      labelAbstentionError: null,
      exprimableValueError: null,
      error: null,
      presentTemplates: null,
      voteTemplates: null,
      resultTemplates: null,
      groups: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#updateQuestionModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    presentTemplateChange(template)
    {
      if(template && template.properties)
      {
        if(this.question.data  == undefined)
        {
          this.question.data = {
            vote: {},
            present: {},
            result: {}
          }
        }

        for(const [propertyName, property] of Object.entries(template.properties))
        {
          
            if(this.question.data.present == undefined)
            {
              this.question.data.present = {};
            }

            if(this.question.data.present[propertyName] == undefined)
            {
              this.question.data.present[propertyName] = property.default;
            }
          
          
        }
      }
    },
    voteTemplateChange(template)
    {
      if(template && template.properties)
      {
        if(this.question.data  == undefined)
        {
          this.question.data = {
            vote: {},
            present: {},
            result: {}
          }
        }

        for(const [propertyName, property] of Object.entries(template.properties))
        {
          
            if(this.question.data.vote == undefined)
            {
              this.question.data.vote = {};
            }

            if(this.question.data.vote[propertyName] == undefined)
            {
              this.question.data.vote[propertyName] = property.default;
            }
          
          
        }
      }
    },
    resultTemplateChange(template)
    {
      if(template && template.properties)
      {
        if(this.question.data  == undefined)
        {
          this.question.data = {
            vote: {},
            present: {},
            result: {}
          }
        }

        for(const [propertyName, property] of Object.entries(template.properties))
        {
          if(this.question.data.result == undefined)
          {
            this.question.data.result = {};
          }

          if(this.question.data.result[propertyName] == undefined)
          {
            this.question.data.result[propertyName] = property.default;
          }
          
        }
      }
    },
    loadPresentTemplates()
    {

      templateService.getListPresentForUserAndSession(this.session.id).then(
        response => {
          this.presentTemplates = response;

          for(var template of this.presentTemplates)
          {
            if(this.question.presentTemplate == template.id)
            {
              this.presentTemplateChange(template);
              this.question.presentTemplate = template;
              
            }
          }
          
        },
        errors => {
          
        }
      );
      
    },
    loadVoteTemplates()
    {

      templateService.getListVoteForUserAndSession(this.session.id).then(
        response => {
          this.voteTemplates = response;

          for(var template of this.voteTemplates)
          {
            if(this.question.voteTemplate == template.id)
            {
              this.voteTemplateChange(template);
              this.question.voteTemplate = template;
              
            }
          }
          
        },
        errors => {
          
        }
      );
      
    },
    loadResultTemplates()
    {

      templateService.getListResultForUserAndSession(this.session.id).then(
        response => {
          this.resultTemplates = response;

          for(var template of this.resultTemplates)
          {
            if(this.question.resultTemplate == template.id)
            {
              this.resultTemplateChange(template);
              this.question.resultTemplate = template;
              
            }
          }
          
        },
        errors => {
          
        }
      );
      
    },
    loadGroups()
    {

      groupService.getList(this.session).then(
        response => {
          this.groups = response;
          
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetMessages()
    {
      this.textError = null;
      this.minChoiceError = null;
      this.maxChoiceError = null;
      this.voteTemplateError = null;
      this.presentTemplateError = null;
      this.resultTemplateError = null;
      this.labelAbstentionError = null;
      this.exprimableValueError = null;
      
      this.error = null;
    },    
    openModal() {

      this.updateModal.show();
    },
    
    updateQuestion() {
      
      this.resetMessages();

      this.question.session = this.session.id;

      if(this.question.voteTemplate)
      {
        this.question.voteTemplate = this.question.voteTemplate.id;
      }

      if(this.question.presentTemplate)
      {
        this.question.presentTemplate = this.question.presentTemplate.id;
      }

      if(this.question.resultTemplate)
      {
        this.question.resultTemplate = this.question.resultTemplate.id;
      }

      questionService.update(this.question).then(
        response => {
          this.updateModal.hide();
          this.$emit(eventConfig.UPDATED, response);
        },
        errors => {
          this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'text':
              this.textError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'minChoice':
              this.minChoiceError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'maxChoice':
              this.maxChoiceError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'presentTemplate':
              this.presentTemplateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'voteTemplate':
              this.voteTemplateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;            
            case 'resultTemplate':
              this.resultTemplateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'labelAbstention':
              this.labelAbstentionError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'exprimableValue':
              this.exprimableValueError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>