import apiService from './apiService';

export default class templateService {



    static getListVote () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listVote/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getListPresent () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listPresent/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }    

    static getListResult () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listResult/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
        

    static getListScreen () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listScreen/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static getListQas () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listQas/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static getListVoteForUserAndSession (sessionId) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listVoteForUserAndSession/"+sessionId)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static getListPresentForUserAndSession (sessionId) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listPresentForUserAndSession/"+sessionId)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }    

    static getListResultForUserAndSession (sessionId) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listResultForUserAndSession/"+sessionId)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
        

    static getListScreenForUserAndSession (sessionId) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listScreenForUserAndSession/"+sessionId)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static getListQasForUserAndSession (sessionId) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/template/listQasForUserAndSession/"+sessionId)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

}
