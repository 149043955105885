import apiService from './apiService';

export default class voterService {


    static getList (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/voter/listBySession/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static previewCsv (file, data) {
        
        return new Promise((resolve, reject) => {

            apiService.upload("/voter/previewCsv", file, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                
                reject(error);   
            });
         });
       
    }

    static importCsv (file, session) {
        
        return new Promise((resolve, reject) => {

            apiService.upload("/voter/importCsv", file, session)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }
   
    static create (voter) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/voter/new", voter)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (voter) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/voter/edit", voter)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (voter) {
        
        return new Promise((resolve, reject) => {
            apiService.post("/voter/remove", voter)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

    static disconnect (voter) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/voter/disconnect/"+voter.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static reconnect (voter) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/voter/reconnect/"+voter.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static ban (voter) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/voter/ban/"+voter.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

    static unban (voter) {
        
        return new Promise((resolve, reject) => {
            apiService.patch("/voter/unban/"+voter.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 

}
