import apiService from './apiService';

export default class messageService {


    static sendMessage (message) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/message/sendByAdmin/", message)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static markReadMessages (messages) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/message/markReadMessages", {messages: messages})
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    

}
