<template>

<div id="banVoterModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div v-if="voter" class="modal-content">
      <div class="modal-header">
        <h5 v-if="!voter.banned" class="modal-title text-danger">{{ $t("voter.ban.title") }}</h5>
        <h5 v-if="voter.banned" class="modal-title">{{ $t("voter.unban.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("voter.ban.are_you_sure") }}</p>
        <p v-if="!voter.banned" v-html="$t('voter.ban.instructions', {firstName: voter.firstName, lastName: voter.lastName})"></p>
        <p v-if="voter.banned" v-html="$t('voter.unban.instructions', {firstName: voter.firstName, lastName: voter.lastName})"></p>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button v-if="!voter.banned" type="button" class="btn btn-danger" @click="banVoter()">{{ $t("voter.ban.understand_message") }}</button>
        <button v-if="voter.banned" type="button" class="btn btn-danger" @click="unbanVoter()">{{ $t("voter.unban.understand_message") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("voter.ban.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import voterService from '@/services/voterService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'VoterBanComponent',
  props: {
    voterToBan: Object
  },
  watch: 
  { 
    voterToBan: function() {
      if(this.voterToBan)
      {
        this.voter = JSON.parse(JSON.stringify(this.voterToBan));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      voter: null,
      error: null,
      banModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.banModal = new Modal($('#banVoterModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.banModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.error = null;
      this.banModal.show();
    },
    banVoter()
    {
      
        voterService.ban(this.voter).then(
          response => {
            this.banModal.hide();
            this.$emit(eventConfig.BANNED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    unbanVoter()
    {
      
        voterService.unban(this.voter).then(
          response => {
            this.banModal.hide();
            this.$emit(eventConfig.BANNED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      
    }
  }
}
</script>