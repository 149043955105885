const DocumentSocket = require("./DocumentSocket");
const DocumentSocketType = require("./DocumentSocketType");

class ScreenSocket extends DocumentSocket {
    constructor(documentId, screen) {
        
        super(documentId, DocumentSocketType.SCREEN, null, screen);
    }

}
module.exports = ScreenSocket;