<template>

  <div id="newScreenModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("screen.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("screen.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("screen.new.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="screen.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label for="templateSelect">{{ $t("screen.new.template_label") }}</label>
              <select class="form-select form-select-sm" v-model="screen.template" :class="{ 'is-invalid': templateError}" @change="templateChange(screen.template)">
                <option v-for="template in templates" v-bind:key="template.id" v-bind:value="template">{{ template.name }}</option>
              </select>
              <div v-if="templateError" class="invalid-feedback">
                {{templateError}}
              </div>   
            </div>

            <div class="form-group mb-3" v-if="screen.template">
              <label>{{ $t("screen.new.template_custom_value_label") }}</label>
              <fieldset class="scheduler-border">
                <div v-for="(property, propertyName) in screen.template.properties" v-bind:key="property">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'String'">
                    <label class="form-label" :for="propertyName">{{property.help}}</label>
                    <textarea class="form-control" :id="propertyName" rows="3" v-model="screen.data[propertyName]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'Boolean'">
                    <input class="form-check-input" type="checkbox" :id="propertyName" :checked="property.default" v-model="screen.data[propertyName]">
                    <label class="form-check-label" :for="propertyName">{{property.help}}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("screen.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createScreen()">{{ $t("screen.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import templateService from '@/services/templateService';
import screenService from '@/services/screenService';

const emptyScreen = {
       name: null,
       template: null,
       data: {}
     };

export default {
  name: 'ScreenNewComponent',
  props: {
    action: Boolean,
    session: Object
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {

        Promise.all([this.loadTemplates()]).then(() => {
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      screen: emptyScreen,
      newModal: null,
      nameError: null,
      templateError: null,
      error: null,
      templates: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newScreenModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    templateChange(template)
    {
      if(template && template.properties)
      {
        for(const [propertyName, property] of Object.entries(template.properties))
        {
          if(this.screen.data == undefined)
          {
            this.screen.data = {};
          }

          if(this.screen.data[propertyName] == undefined)
          {
            this.screen.data[propertyName] = property.default;
          }
          
        }
      }
    },
    loadTemplates()
    {

      templateService.getListScreenForUserAndSession(this.session.id).then(
        response => {
          this.templates = response;
          
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      
      this.screen = JSON.parse(JSON.stringify(emptyScreen));
      this.newModal.show();
    },
    createScreen() {
      
      this.nameError = null;
      this.templateError = null;

      this.screen.session = this.session.id;

      if(this.screen.template)
      {
        this.screen.template = this.screen.template.id;
      }
      
      screenService.create(this.screen).then(
        response => {
          this.newModal.hide();
          this.$emit(eventConfig.CREATED, response);
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'template':
              this.templateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>
<style scoped>
fieldset.scheduler-border {
    border: 1px solid #ced4da;
    padding: 20px;
    border-radius: 0.25rem;

}


</style>