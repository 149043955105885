<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a v-if="managingGroupAllowed" class="nav-link mb-2" @click="createGroup()">
            <img src="@/assets/images/ico-add.svg" alt="Add group">
        </a>
      </div>
  </nav>
  <div v-if="readingGroupAllowed" class="row m-0 flex-grow-1">
    <div class="col col-12 p-0">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t("group.list.name_label") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="group in groups" v-bind:key="group.id">
            <td scope="row">{{group.name}}</td>
            <td class="actions me-2 text-end">
                <a v-if="managingGroupAllowed" class="edit" @click="updateGroup(group)"></a>
                <a v-if="managingGroupAllowed" class="delete" @click="deleteGroup(group)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    
  </div>

  <group-new-component @created="createdGroup" @canceled="createGroupCanceled" :session="session" :action="newAction" />
  <group-update-component @updated="updatedGroup" @canceled="updateGroupCanceled" :session="session" :groupToUpdate="groupToUpdate" />
  <group-delete-component @deleted="deletedGroup" @canceled="deleteGroupCanceled" :session="session" :groupToDelete="groupToDelete" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import groupService from '@/services/groupService';
import eventConfig from '@/configs/eventConfig';

import GroupNewComponent from '../group/GroupNewComponent.vue'
import GroupUpdateComponent from '../group/GroupUpdateComponent.vue'
import GroupDeleteComponent from '../group/GroupDeleteComponent.vue'
import privilegeConfig from '@/configs/privilegeConfig';

export default {
  name: 'GroupsComponent',
  emits: [eventConfig.LOADED],
  props: {
    session: Object,
    superadmin: Boolean,
    loadAction: Boolean,
  },
  data: function () {
    return {
      groups: null,
      eventConfig: eventConfig,
      newAction: false,
      groupToUpdate: null,
      groupToDelete: null,
      readingGroupAllowed: false,
      managingGroupAllowed: false
    }
  },
  components: {
    'group-new-component': GroupNewComponent,
    'group-update-component': GroupUpdateComponent,
    'group-delete-component': GroupDeleteComponent
  },
  mounted () {
    this.loadGroups();
    this.setPrivileges();
  },
  watch: 
  { 
    loadAction: function() {
      if(this.loadAction)
      {
        this.loadGroups();
        this.setPrivileges();
      }
    }
  },
  methods: 
  {
    setPrivileges()
    {
      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_GROUP)) )
      {
        this.readingGroupAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_GROUP)) )
      {
        this.managingGroupAllowed = true;
      }

    },
    loadGroups() { 
      groupService.getList(this.session).then(
        response => {
          this.groups = response;

          this.$emit(eventConfig.LOADED);
        },
        errors => {
          
        }
      );
    },
    createGroup()
    {
      this.newAction = true;
    },
    createdGroup(group)
    {
      this.newAction = false;
      this.loadGroups();
    },
    createGroupCanceled()
    {
      this.newAction = false;
    },
   
    updateGroup(group)
    {
      this.groupToUpdate = group;
    },
    updatedGroup(group)
    {
      
      this.groupToUpdate = null;
      this.loadGroups();
    },
    updateGroupCanceled()
    {
      this.groupToUpdate = null;
    },    
    
    deleteGroup(group)
    {
      this.groupToDelete = group;
    },
    deleteGroupCanceled()
    {
      this.groupToDelete = null;
    },
    deletedGroup()
    {
      this.groupToDelete = null;
      this.loadGroups();
    }
  }
 
}
</script>

<style scoped lang="scss">



.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}


.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

.btn.btn-light {
  border: 1px solid black;
}

.btn .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: left center;
}

.btn .icon.show {
  background-image: url(@/assets/images/ico-show.svg);
}
.btn.active .icon.show {
  background-image: url(@/assets/images/ico-show-on.svg);
}
</style>