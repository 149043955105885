<template>

<div id="deleteTemplateGroupModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("templateGroups.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("templateGroups.delete.absolutely_sure") }}</p>
        <p v-if="templateGroup" v-html="$t('templateGroups.delete.action', {name: templateGroup.name})"></p>
        <p v-if="templateGroup" v-html="$t('templateGroups.delete.actionCode', {code: templateGroup.code})"></p>
        <div class="form-group mb-3">
            <label for="codeInput">{{ $t("templateGroups.delete.templategroup_code") }}</label>
            <input type="text" class="form-control" id="codeInput" v-model="deleteCode" :class="{ 'is-invalid': deleteCodeError}" @input="deleteCodeError = null" @click.right.prevent @copy.prevent @paste.prevent>
            <div v-if="deleteCodeError" class="invalid-feedback">
              {{deleteCodeError}}
            </div>
        </div>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeTemplateGroup()">{{ $t("templateGroups.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("templateGroups.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import templateGroupService from '../../services/templateGroupService';
import authService from '../../services/authService';

import eventConfig from '@/configs/eventConfig';

export default {
  name: 'TemplateGroupDeleteComponent',
  props: {
    templateGroupToDelete: Object
  },
  watch: 
  { 
    templateGroupToDelete: function() {
      if(this.templateGroupToDelete)
      {
        this.templateGroup = JSON.parse(JSON.stringify(this.templateGroupToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      templateGroup: null,
      error: null,
      currentCode: null,
      deleteCode: null,
      deleteCodeError: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteTemplateGroupModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.deleteCode = null;
      this.error = null;
      this.deleteCodeError = null;
      this.deleteModal.show();
    },
    removeTemplateGroup()
    {
      if(this.deleteCode === this.templateGroup.code)
      {
        templateGroupService.delete(this.templateGroup).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      }else
      {
        this.deleteCodeError = this.$t("templateGroups.delete.code_doesnt_match");
      }
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'code':
              this.codeError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>