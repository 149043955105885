<template>
  <div class="container-fluid h-100 after-navbar">
    <div class="row h-100">
      <div class="col col-12 col-lg-8 col-xl-9 p-0 h-100 d-flex flex-column">
       <streams-component :session="session" :superadmin="superadmin" />
      </div>
      <div class="col col-12 col-lg-4 col-xl-3 h-100 d-flex flex-column border-left p-0">
        <channels-component :session="session" :superadmin="superadmin" />
        <messages-component :session="session" :superadmin="superadmin" />
      </div>
    </div>
  </div>
</template>

<script>
import StreamsComponent from "@/components/stream/StreamsComponent.vue";
import ChannelsComponent from "@/components/channel/ChannelsComponent.vue";
import MessagesComponent from "@/components/message/MessagesComponent.vue";

export default {
  name: 'DashboardComponent',
  props: {
    session: Object,
    superadmin: Boolean
  },
  components: {
    'streams-component': StreamsComponent,
    'channels-component': ChannelsComponent,
    'messages-component': MessagesComponent,
  }
}
</script>
