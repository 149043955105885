<template>

  <div id="newStreamModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("stream.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("stream.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("stream.new.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="stream.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label for="templateSelect">{{ $t("stream.new.qa_template_label") }}</label>
              <select class="form-select form-select-sm" v-model="stream.qasTemplate" :class="{ 'is-invalid': qasTemplateError}" @change="templateChange(stream.qasTemplate)">
                <option v-for="qasTemplate in qasTemplates" v-bind:key="qasTemplate.id" v-bind:value="qasTemplate">{{ qasTemplate.name }}</option>
              </select>
              <div v-if="qasTemplateError" class="invalid-feedback">
                {{qasTemplateError}}
              </div>   
            </div>

            <div class="form-group mb-3" v-if="stream.qasTemplate">
              <label>{{ $t("stream.new.qa_custom_values_label") }}</label>
              <fieldset class="scheduler-border">
                <div v-for="(property, propertyName) in stream.qasTemplate.properties" v-bind:key="property">
                  
                  <div class="form-outline mb-3" v-if="property.format == 'String'">
                    <label class="form-label" :for="propertyName">{{property.help}}</label>
                    <textarea class="form-control" :id="propertyName" rows="3" v-model="stream.data[propertyName]"></textarea>
                  </div>

                  <div class="form-check form-switch mb-3" v-if="property.format == 'Boolean'">
                    <input class="form-check-input" type="checkbox" :id="propertyName" :checked="property.default" v-model="stream.data[propertyName]">
                    <label class="form-check-label" :for="propertyName">{{property.help}}</label>
                  </div>
                  
                </div>
              </fieldset>
            </div>

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("stream.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createStream()">{{ $t("stream.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import streamService from '@/services/streamService';
import templateService from '@/services/templateService';

const emptyStream = {
       name: null,
       qasTemplate: null,
       data: {}
     };

export default {
  name: 'StreamNewComponent',
  props: {
    action: Boolean,
    session: Object
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
        Promise.all([this.loadTemplates()]).then(() => {
          this.openModal();
        });
      }
    }
  },
  data: function () {
    return {
      stream: emptyStream,
      newModal: null,
      nameError: null,
      qasTemplateError: null,
      error: null,
      qasTemplates: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newStreamModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    templateChange(template)
    {
      if(template && template.properties)
      {
        for(const [propertyName, property] of Object.entries(template.properties))
        {
          if(this.stream.data == undefined)
          {
            this.stream.data = {};
          }

          if(this.stream.data[propertyName] == undefined)
          {
            this.stream.data[propertyName] = property.default;
          }
          
        }
      }
    },
    loadTemplates()
    {
      templateService.getListQasForUserAndSession(this.session.id).then(
        response => {
          this.qasTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetCurrentStream()
    {
      this.stream = JSON.parse(JSON.stringify(emptyStream));
      this.nameError = null;
      this.qasTemplateError = null;
      this.error = null;
    },
    openModal() {
      this.resetCurrentStream();
      this.newModal.show();
    },
    createStream() {
      
      this.nameError = null;
      this.qasTemplateError = null;

      if(this.stream.qasTemplate)
      {
        this.stream.qasTemplate = this.stream.qasTemplate.id;
      }

      this.stream.session = this.session.id;
      streamService.create(this.stream).then(
        response => {
          this.newModal.hide();
          this.$emit(eventConfig.CREATED, response);
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;

            case 'qasTemplate':
              this.qasTemplateError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>