import apiService from './apiService';

export default class userSessionTemplatesService {


    static getList (user) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/userSessionTemplates/list/"+user.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

   

    static update (data) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/userSessionTemplates/edit", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      


}
