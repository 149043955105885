const DocumentSocketStatus = require("./DocumentSocketStatus");
const QuestionSocket = require("./QuestionSocket");



class PresentSocket extends QuestionSocket {
    constructor(documentId, question, answers) {
        
        super(documentId, question, answers, DocumentSocketStatus.PRESENT );
    }


}
module.exports = PresentSocket;