<template>
<div id="editPrivilegesUserModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div v-if="user" class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("user.updatePrivileges.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p v-html="$t('user.updatePrivileges.action', {firstName: user.firstName, lastName: user.lastName})"></p>
          <form @submit.prevent="">
            


            <table class="table">
              <thead>
                <tr>
                  <th scope="col">{{ $t("user.updatePrivileges.sessionLabel") }}</th>
                  <th scope="col">{{ $t("user.updatePrivileges.privilegeGroupsLabel") }}</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr v-for="session in sessions" v-bind:key="session.id">
                  <th>{{session.name}}</th>
                  <td>
                    <fieldset class="scheduler-border">
                    
                      <div class="form-check" v-for="(privilegeGroup, index) in privilegeGroups" v-bind:key="index" >
                        <input class="form-check-input" type="checkbox" :id="'g'+index+'s'+session.id" :value="privilegeGroup.id" v-model="userSessionsPrivileges[session.id]">
                        <label class="form-check-label" :for="'g'+index+'s'+session.id">
                          {{ privilegeGroup.name }}
                        </label>
                      </div>
                    </fieldset>
                  </td>
                  
                </tr>
              </tbody>
            </table>

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("user.updatePrivileges.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="updateUserSessionPrivileges()">{{ $t("user.updatePrivileges.update_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'


import eventConfig from '@/configs/eventConfig';
import userSessionPrivilegesService from '@/services/userSessionPrivilegesService';
import userService from '@/services/userService';
import privilegeGroupService from '@/services/privilegeGroupService';
import sessionService from '@/services/sessionService';


export default {
  name: 'UserUpdateComponent',
  props: {
    userToEditPrivileges: Object,
    session: Object
  },
  watch: 
  { 
    userToEditPrivileges: function() {
      if(this.userToEditPrivileges)
      {
        this.user = JSON.parse(JSON.stringify(this.userToEditPrivileges));

        
        

        this.resetErrors();
        Promise.all([this.loadPrivilegeGroups(), this.loadSessions()]).then(() => {
          this.openModal();
        });
      }
    }
  },
  data: function () {
    return {
      user: null,
      updateModal: null,
      error: null,
      privilegeGroups: null,
      sessions: null,
      userSessionsPrivileges: []
    }
  },
  components: {
    
  },
  mounted () {
    
    this.updateModal = new Modal($('#editPrivilegesUserModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    resetErrors()
    {
      
      this.error = null;
    },
    loadUserSessionPrivileges()
    {
      
      userSessionPrivilegesService.getList(this.user).then(
        response => {
          
           
          for(var uspg of response)
          {
            this.userSessionsPrivileges[uspg.session] = uspg.privilegesGroups;
          }
          
          
        
        },
        errors => {
          
        }
      );
      
    },
    loadPrivilegeGroups()
    {
      privilegeGroupService.getList(this.user).then(
        response => {
          this.privilegeGroups = response;
          
        },
        errors => {
          
        }
      );
      
    },
    loadSessions()
    {
      sessionService.getList().then(
        response => {
          this.sessions = response;
          
          this.userSessionsPrivileges = [];

          for(const session of this.sessions)
          {
            this.userSessionsPrivileges[session.id] = [];
          }

          this.loadUserSessionPrivileges();
        },
        errors => {
          
        }
      );
      
    },
    cancel()
    {
      this.updateModal.hide();
      this.$emit(eventConfig.CANCELED);
    },  
    openModal() {

      this.updateModal.show();
    },
    updateUserSessionPrivileges() {
      
      this.resetErrors();

      var sessionsPrivileges = [];
      for(const [propertyName, property] of Object.entries(this.userSessionsPrivileges))
      {
        sessionsPrivileges.push({
          session: propertyName,
          privilegesGroups: property
        })
      }

      var data = {
        user: this.user.id,
        sessionsPrivileges: sessionsPrivileges
      }

        userSessionPrivilegesService.update(data).then(
          response => {
            this.updateModal.hide();
            this.$emit(eventConfig.UPDATED, response);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      
      
    },
    parseErrors(data)
    {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
     
    }
  }
}
</script>