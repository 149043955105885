<template>
  <div class="messages d-flex flex-column flex-grow-1">

    <nav v-if="readingMessageAllowed || sendingMessageAllowed" class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <h6 class="mt-2"><b>{{ $t("message.title") }}</b></h6>
        <div class="author pe-3" v-if="currentVoter">
          <span class="initial active">{{ initialsOf(currentVoter.firstName, currentVoter.lastName) }}</span> {{currentVoter.firstName}} {{currentVoter.lastName}}
        </div>
      </div>
    </nav>

    <div v-if="readingMessageAllowed" id="messageContainer" class="flex-grow-1 d-flex flex-row">
      <div class="authors border-right">

        <div class="author" v-for="voter in voters" v-bind:key="voter.id">
          <a class="initial" :class="{'active': voter == currentVoter}" @click="openVoterMessages(voter)">{{ initialsOf(voter.firstName, voter.lastName) }}
          <span v-if="unreadMessageForVoter(voter) > 0" class="author-badge">
            {{unreadMessageForVoter(voter)}}
          </span>
          </a>
        </div>

      </div>

      <div class="chat flex-grow-1 d-flex flex-column">
        <div class="messages" v-if="currentVoter">
          
          <div v-for="message in getMessagesByCurrentVoter()" v-bind:key="message.id" class="message d-flex"  :class="{'from': message.voterFrom}">
              <div v-if="message.voterFrom" class="initial flex-shrink-0" :class="{'active': message.voterFrom}">{{ initialsOf(message.voterFromFirstName, message.voterFromLastName) }}</div>
              <div class="text">
                <span>
                  {{message.text}}
                </span>
                <a v-if="message.voterFrom && !message.pinned && session.qasActivated && managingQasAllowed" class="pin" @click="createQA(message)">
                  <span>
                    Actions
                  </span>
                </a>
              </div>
            </div>

          
        </div>

        <div class="form" v-if="currentVoter">
          <form v-if="sendingMessageAllowed"  @submit.prevent="sendMessage" class="d-flex">
            <input class="flex-grow-1" type="text" :placeholder="$t('message.your_message_label')" v-model="messageToSend">
            <button type="submit"><span>{{ $t("message.send_label") }}</span></button>
          </form>
        </div>      
      </div>
    </div>

  </div>
</template>

<script>
import socketConfig from '@/configs/socketConfig';
import messageService from '@/services/messageService';
import $ from 'jquery';
import { io } from 'socket.io-client';
import qaService from '@/services/qaService';
import eventConfig from '@/configs/eventConfig';
import privilegeConfig from '@/configs/privilegeConfig';

export default {
  name: 'MessagesComponent',
  data: function () {
    return {
      messages: [],
      voters: [],
      socket: null,
      currentVoter: null,
      messageToSend: null,
      managingMessageAllowed: false,
      readingMessageAllowed: false,
      sendingMessageAllowed: false,
      managingQasAllowed: false
    }
  },
  props: {
    session: Object,
    superadmin: Boolean
  },
  components: {
    
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
          this.connectSocket();
          this.setPrivileges();
      }
    }
  },
  mounted () {

      setTimeout(() => {
        $(".authors").css('max-height', "0px" );
        $(".authors").css('min-height', "0px" );
        $(".chat .messages").css('max-height', "0px" );
        $(".chat .messages").css('min-height', "0px" );
        this.resize();  
      }, 300);

    $(window).resize(()=>{
      $(".authors").css('max-height', "0px" );
      $(".authors").css('min-height', "0px" );
      $(".chat .messages").css('max-height', "0px" );
      $(".chat .messages").css('min-height', "0px" );
      this.resize();
    });
    
  
      this.connectSocket();
  
  },
  methods: 
  {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_MESSAGE)) )
      {
        this.managingMessageAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_MESSAGE)) )
      {
        this.readingMessageAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.SEND_MESSAGE)) )
      {
        this.sendingMessageAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_QAS)) )
      {
        this.managingQasAllowed = true;
      }

      
    },
    sendMessage()
    {
      
      var message = {
        text: this.messageToSend,
        voterTo: this.currentVoter.id,
        session: this.session.id
      }
      messageService.sendMessage( message ).then(
        response => {
          this.messageToSend = null;
          this.socket.emit(socketConfig.GET_SESSION_MESSAGES, this.session.id);
        },
        errors => {
          
        }
      );
    },
    createQA(message)
    {
      var qa = {
        text: message.text,
        firstName: message.voterFromFirstName,
        lastName: message.voterFromLastName,
        displayName: true,
        session: this.session.id
      };  
 
      qaService.create(qa).then(
        response => {
          message.pinned = true;
          this.eventDispatcher.emit(eventConfig.RELOAD_QAS);
        },
        errors => {
          
        }
      );
    },
    openVoterMessages(voter)
    {
      this.currentVoter = voter;
      this.markReadMessages();
      setTimeout(() => {
        $(".authors").css('max-height', "0px" );
        $(".authors").css('min-height', "0px" );
        $(".chat .messages").css('max-height', "0px" );
        $(".chat .messages").css('min-height', "0px" );
        this.resize();
        this.scrollBottom();
      }, 300);
      
    },
    markReadMessages()
    {
      var messages = this.markReadMessagesByCurrenVoter();
      messageService.markReadMessages( messages ).then(
        response => {
          
        },
        errors => {
          
        }
      );
    },
    getMessagesByCurrentVoter() {
      var messages = [];
      for(var message of this.messages)
      {
        if(message.voterFrom == this.currentVoter.id || message.voterTo == this.currentVoter.id)
        {
          messages.push(message);
        }
      }


      return messages;
    },
    markReadMessagesByCurrenVoter() {
      var messages = [];
      for(var message of this.messages)
      {
        if(message.voterFrom == this.currentVoter.id || message.voterTo == this.currentVoter.id)
        {
          message.readAt = Date.now();
          messages.push(message);
        }
      }

      return messages;
    },
    parseMessages() {
      for(var message of this.messages)
      {
        var voterFound = false;
        for(var voter of this.voters)
        {
          if(voter.id == message.voterFrom)
          {
            voterFound = true;
          }
        }

        if(!voterFound)
        {
          if(message.voterFrom)
          {
            this.voters.push({
              id: message.voterFrom,
              firstName: message.voterFromFirstName,
              lastName: message.voterFromLastName
            });
          }
        }
      }
    },
    unreadMessageForVoter(voter) {
      var unreadMessagesNumber = 0;

      for(var message of this.messages)
      {
        if(message.voterFrom == voter.id)
        {
          if(!message.readAt)
          {
            unreadMessagesNumber++;
          }
        }
      }

      return unreadMessagesNumber;
    },
    initialsOf(firstName, lastName) {
      if(firstName && lastName)
      {
        return firstName.charAt(0).toUpperCase()+lastName.charAt(0).toUpperCase();
      }
      return "";
    },
    resize() {
      var messageContainerHeight = $("#messageContainer").height();
      var formHeight = $(".form").height();
      $(".authors").css('max-height', messageContainerHeight+"px" );
      $(".authors").css('min-height', messageContainerHeight+"px" );
      $(".chat .messages").css('max-height', (messageContainerHeight-formHeight)+"px" );
      $(".chat .messages").css('min-height', (messageContainerHeight-formHeight)+"px" );
    },
    connectSocket()
    {
      if(this.session && !this.socket)
      {
        this.socket = io(process.env.VUE_APP_SOCKET_URL);
        this.socket.on('connect_error', () => {
          console.log("connect_error");
        });
        this.socket.on("disconnect", (reason) => {
          
              console.log("disconnect reason: "+reason);
      
              /*
              if ( (reason === "io server disconnect") ) {
                  // the disconnection was initiated by the server, you need to reconnect manually
          
              }
              */
            
              
          });
          
        this.socket.on("connect", () => {
          
          this.socket.emit(socketConfig.GET_SESSION_MESSAGES, this.session.id);
        });

        this.socket.on(socketConfig.SEND_SESSION_MESSAGES, (messages) => {

          this.messages = messages;
          this.parseMessages();
          if(this.currentVoter)
          {
            this.markReadMessagesByCurrenVoter();
          }
          setTimeout(() => {
            this.scrollBottom();  
          }, 500);
        
        });

        this.socket.on(socketConfig.SEND_SESSION_MESSAGE, (message) => {
          
          this.messages.push(message);
          this.parseMessages();
          if(this.currentVoter)
          {
            this.markReadMessagesByCurrenVoter();
          }
          setTimeout(() => {
            this.scrollBottom();  
          }, 500);

        });
      }
      
    },
    scrollBottom() {
      $(".chat .messages").animate({ scrollTop: $(".chat .messages").prop("scrollHeight") }, 500);
    }
  }
}
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}


.authors {
  padding: 5px;
  padding-left: 10px;
  padding-right: 20px;
  overflow-y: scroll;
  max-height: 0px;
}

.authors .author {
  margin-top: 8px;
  margin-bottom: 8px;
}

.initial {
   width: 32px;
   height: 32px;
   line-height: 32px;
   text-align: center;
   background-color: #9A9A9A;
   display: inline-block;
   border-radius: 16px;
   font-size: 0.9em;
 }

.author .initial .author-badge {
  background-color: black;
  color: white;
  width: 16px;
  height: 16px;
  display: block;
  line-height: 13px;
  padding: 2px;
  font-size: 0.8em;
  position: relative;
  margin-top: -12px;
  margin-left: 20px;
  border-radius: 8px;
}

.initial.active {
  background-color: black;
  color: white;
}

.chat .messages {
  padding-top: 5px;
  overflow-y: scroll;
  max-height: 0px;  
  padding-left: 10px;
  padding-right: 10px;
}

.message {
  margin-top: 8px;
  margin-bottom: 20px;
}

.message .pin {
  background-image: url(@/assets/images/ico-pin.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  display: block;
  float: right;
}

.message .pin.active {
  background-image: url(@/assets/images/ico-pin-on.svg);
}

.message .pin span {
  display: none;
}

.message .text {
  background-color: #9A9A9A;
  padding: 10px;
  border-radius: 10px;
  border-bottom-right-radius: 0px;
  color: white;
}

.message.from .text {
  margin-left: 10px;
  background-color: black;
  padding: 10px;
  border-radius: 10px;
  border-top-left-radius: 0px;
  color: white;
}

.form {
  padding-left: 10px;
  padding-right: 10px;
}

.form form {
  background-color: #F5F5F5;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.form input, .form input:hover, .form input:focus, .form input:active  {
  border: none;
  background-color: #F5F5F5;
}

.form button {
  border: none;
  background-color: #F5F5F5;
  background-image: url(@/assets/images/ico-send.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 30px;
  height: 30px;
}

.form button span {
  display: none;
}
</style>