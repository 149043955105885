import { createApp } from 'vue'

import "bootstrap"

import App from './App.vue'
import router from './router'
import i18n from './i18n'

import eventDispatcher from './eventDispatcher'

import './sass/app.scss'



import eventConfig from './configs/eventConfig'

const app = createApp(App);
app.use(i18n);
app.use(router);

eventDispatcher.on(eventConfig.STREAM_CHANGE, (data) => {
    
  //console.log(data.stream);
  //console.log(data.document);
  });

app.config.globalProperties.eventDispatcher = eventDispatcher;
app.mount('#app');