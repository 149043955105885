<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a v-if="managingQuestionAllowed" class="nav-link mb-2" @click="createQuestion()">
            <img src="@/assets/images/ico-add.svg" alt="Add question">
        </a>
      </div>
  </nav>
  <div id="questions" class="questions">
    <div class="question ps-3 pe-3 pt-2 pb-2" v-for="question in questions" v-bind:key="question.id" :data-id="question.id">
      <div class="q mt-2 mb-2">
        {{ question.text }}
      </div>
      <div class="d-flex justify-content-between">
        <div class="actions actions-crud me-1">
          <a v-if="managingQuestionAllowed" class="answers" @click="manageAnswers(question)"></a>
          <a v-if="managingQuestionAllowed" class="edit" @click="updateQuestion(question)"></a>
          <a v-if="managingQuestionAllowed" class="erase" @click="eraseVotes(question)"></a>
          <a v-if="managingQuestionAllowed" class="delete" @click="deleteQuestion(question)"></a>
        </div>
        
        <div class="actions actions-pilot">
          <button v-if="pilotStreamAllowed" class="ms-1 me-1 btn btn-light" @click="changeSocketToPresent(question)" :class="{'active': (this.currentDocumentSocket && this.currentDocumentSocket.documentId == question.id && this.currentDocumentSocket.documentStatus == documentSocketStatus.PRESENT)}"><span class="icon cast"></span> {{ $t("question.list.present_label") }}</button>
          <button v-if="pilotStreamAllowed" class="ms-1 me-1 btn btn-light" @click="changeSocketToVote(question)" :class="{'active': (this.currentDocumentSocket && this.currentDocumentSocket.documentId == question.id && this.currentDocumentSocket.documentStatus == documentSocketStatus.VOTE)}"><span class="icon cast"></span> {{ $t("question.list.vote_label") }}</button>
          <button v-if="pilotStreamAllowed" class="ms-1 btn btn-light" @click="changeSocketToResult(question)" :class="{'active': (this.currentDocumentSocket && this.currentDocumentSocket.documentId == question.id && this.currentDocumentSocket.documentStatus == documentSocketStatus.RESULT)}"><span class="icon cast"></span> {{ $t("question.list.result_label") }}</button>
        </div>
        
      </div>
    </div>


  </div>

  <question-new-component @created="createdQuestion" @canceled="createQuestionCanceled" :session="session" :action="newAction" />
  <question-update-component @updated="updatedQuestion" @canceled="updateQuestionCanceled" :session="session" :questionToUpdate="questionToUpdate" />
  <question-delete-component @deleted="deletedQuestion" @canceled="deleteQuestionCanceled" :session="session" :questionToDelete="questionToDelete" />
  <question-votes-erase-component @erased="erasedVoteQuestion" @canceled="eraseVoteQuestionCanceled" :session="session" :questionVotesToErase="questionVotesToErase" />
  <question-answers-component @canceled="manageAnswersCanceled" @updated="updatedAnswers" :session="session" :questionToManageAnswers="questionToManageAnswers" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import questionService from '@/services/questionService';
import eventConfig from '@/configs/eventConfig';

import QuestionNewComponent from '../question/QuestionNewComponent.vue'
import QuestionAnswersComponent from '../question/QuestionAnswersComponent.vue'
import QuestionUpdateComponent from '../question/QuestionUpdateComponent.vue'
import QuestionDeleteComponent from '../question/QuestionDeleteComponent.vue'
import QuestionVoteEraseComponent from '../question/QuestionVotesEraseComponent.vue'
import templateService from '@/services/templateService';
import DocumentSocketStatus from '@/model/socket/DocumentSocketStatus';
import ResultSocket from '@/model/socket/ResultSocket';
import VoteSocket from '@/model/socket/VoteSocket';
import PresentSocket from '@/model/socket/PresentSocket';
import answerService from '@/services/answerService';
import utilsService from '@/services/utilsService';



export default {
  name: 'QuestionsComponent',
  props: {
    session: Object,
    superadmin: Boolean,
    stream: Object
  },
  emits: [eventConfig.SOCKET_CHANGE],
  data: function () {
    return {
      questions: null,
      voteTemplates: null,
      presentTemplates: null,
      resultTemplates: null,
      eventConfig: eventConfig,
      documentSocketStatus: DocumentSocketStatus,
      newAction: false,
      questionToUpdate: null,
      questionToDelete: null,
      questionVotesToErase: null,
      questionToManageAnswers: null,
      currentDocumentSocket: null,
      managingQuestionAllowed: false,
      readingQuestionAllowed: false,
      pilotStreamAllowed: false,
    }
  },
  components: {
    'question-new-component': QuestionNewComponent,
    'question-answers-component': QuestionAnswersComponent,
    'question-update-component': QuestionUpdateComponent,
    'question-delete-component': QuestionDeleteComponent,
    'question-votes-erase-component': QuestionVoteEraseComponent
  },
  mounted () {
  
    this.currentDocumentSocket = this.stream.documentSocket;

    setTimeout(() => {
      this.resize();  
    }, 300);

    $(window).resize(()=>{
      $(".questions").css('max-height', "0px" );
      this.resize();
    });

    this.eventDispatcher.on(eventConfig.STREAM_CHANGE, (data) => {
      
      if(data.stream.id == this.stream.id)
      {
        this.currentDocumentSocket = data.documentSocket;
      }
      
      });

    Promise.all([this.loadVoteTemplates(), this.loadPresentTemplates(), this.loadResultTemplates() ]).then(() => {
      
      this.loadQuestions();
    });


    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_QUESTION)) )
    {
      this.managingQuestionAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_QUESTION)) )
    {
      this.readingQuestionAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_STREAM)) )
    {
      this.pilotStreamAllowed = true;
    }
    
  
  },
  methods: 
  {
    resize() {
      var streamContainer = $("#streamContainer").height();
      $(".questions").css('max-height', (streamContainer-64)+"px" );
    },
    loadVoteTemplates() { 
      templateService.getListVote().then(
        response => {
          this.voteTemplates = response;
        },
        errors => {
          
        }
      );
    },
    loadPresentTemplates() { 
      templateService.getListPresent().then(
        response => {
          this.presentTemplates = response;
        },
        errors => {
          
        }
      );
    },    
    loadResultTemplates() { 
      templateService.getListResult().then(
        response => {
          this.resultTemplates = response;
        },
        errors => {
          
        }
      );
    },
    loadQuestions() {  

      questionService.getList(this.session).then(
        response => {

          this.questions = response;

          if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_QUESTION)) )
          {
            $("#questions").sortable({
              update: () => {
                var orderedQuestionsId = [];
                  $("#questions .question").each((i,elem)=>{
                    if($(elem).attr('data-id') != undefined)
                    {
                      orderedQuestionsId.push( $(elem).attr('data-id') );
                    }
                  });

                this.updateQuestionsRank(orderedQuestionsId);
                
              }
            });
          }
          
        },
        errors => {
          
        }
      );
    },
    updateQuestionsRank(orderedQuestionsId)
    {
      
      questionService.reorder(orderedQuestionsId).then(
        response => {
          
        },
        errors => {
          
        }
      );
    },
    createQuestion()
    {
      this.newAction = true;
    },
    createdQuestion(question)
    {
      this.newAction = false;
      this.loadQuestions();
    },
    createQuestionCanceled()
    {
      this.newAction = false;
    },
   
    updateQuestion(question)
    {
      this.questionToUpdate = question;
    },
    updatedQuestion(question)
    {
      
      this.questionToUpdate = null;
      this.loadQuestions();
    },
    updateQuestionCanceled()
    {
      this.questionToUpdate = null;
    },    
    
    deleteQuestion(question)
    {
      this.questionToDelete = question;
    },
    eraseVotes(question)
    {
      this.questionVotesToErase = question;
    },    
    deleteQuestionCanceled()
    {
      this.questionToDelete = null;
    },
    eraseVoteQuestionCanceled()
    {
      this.questionVotesToErase = null;
    },
    deletedQuestion()
    {
      this.questionToDelete = null;
      this.loadQuestions();
    },
    erasedVoteQuestion()
    {
      this.questionVotesToErase = null;
      this.loadQuestions();
    },
    manageAnswers(question)
    {
        this.questionToManageAnswers = question;
    },

    manageAnswersCanceled()
    {
      this.questionToManageAnswers = null;
    },
    updatedAnswers()
    {
      this.questionToManageAnswers = null;
    },
    changeSocketToPresent(question)
    {
      this.changeSocket(question, DocumentSocketStatus.PRESENT);
    },
    changeSocketToVote(question)
    {
      this.changeSocket(question, DocumentSocketStatus.VOTE);
    },
    changeSocketToResult(question)
    {
      this.changeSocket(question, DocumentSocketStatus.RESULT);
    },
    changeSocket(question, state)
    {
      var questionWithTemplate = JSON.parse(JSON.stringify(question));
      
      for(const t of this.voteTemplates)
      {
        if(t.id == questionWithTemplate.voteTemplate)
        {
          questionWithTemplate.voteTemplate = t;
          break;
        }
      }

      for(const t of this.presentTemplates)
      {
        if(t.id == questionWithTemplate.presentTemplate)
        {
          questionWithTemplate.presentTemplate = t;
          break;
        }
      }

      for(const t of this.resultTemplates)
      {
        if(t.id == questionWithTemplate.resultTemplate)
        {
          questionWithTemplate.resultTemplate = t;
          break;
        }
      }

      const data = {
        question: questionWithTemplate.id,
        session: this.session.id
      }

      answerService.getListByQuestion(data).then(
        response => {
          var answers = response;
          
          let doc;
          switch(state)
          {
            case DocumentSocketStatus.PRESENT:
              doc = new PresentSocket(questionWithTemplate.id, questionWithTemplate, answers);
            break;
            case DocumentSocketStatus.VOTE:
              doc = new VoteSocket(questionWithTemplate.id, questionWithTemplate, answers);
            break;
            case DocumentSocketStatus.RESULT:
              doc = new ResultSocket(questionWithTemplate.id, questionWithTemplate, answers);
            break;
          }
          
          this.$emit(eventConfig.SOCKET_CHANGE, doc);


        },
        errors => {
          
        }
      );

      
    }
  }
 
}
</script>

<style scoped lang="scss">

.questions {
  max-height: 0px;
  overflow-y: scroll;
}

.question.active {
  background-color: #E0E0E0;
}

.q {
  line-height: 35px;
  padding-left: 45px;
  background-image: url(@/assets/images/ico-qcm.svg);
  background-repeat: no-repeat;
  background-position: top left;
}


.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.actions a.answers {
  background-image: url(@/assets/images/ico-answers.svg);
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.erase {
  background-image: url(@/assets/images/ico-erase.svg);
}

.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

.btn.btn-light {
  border: 1px solid black;
}

.btn .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 6px;
}

.btn.active {
  background-color: black;
  color: white;
}

.btn .icon.vote {
  background-image: url(@/assets/images/ico-vote.svg);
}
.btn.active .icon.vote {
  background-image: url(@/assets/images/ico-vote-on.svg);
}

.btn .icon.result {
  background-image: url(@/assets/images/ico-result.svg);
}
.btn.active .icon.result {
  background-image: url(@/assets/images/ico-result-on.svg);
}


.btn .icon.cast {
  background-image: url(@/assets/images/ico-cast.svg);
}
.btn.active .icon.cast {
  background-image: url(@/assets/images/ico-cast-on.svg);
}
</style>