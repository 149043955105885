<template>
  <div id="page" class="p-0">
    <home-navbar-component :superadmin="superadmin" :state="state" />
    <session-navbar-component @new="createSession" :superadmin="superadmin" />
    <session-list-component @show="showSession" @manageGroups="manageGroupsSession" @update="updateSession" @delete="deleteSession" :superadmin="superadmin" />
    <session-new-component @created="createdSession" @canceled="createSessionCanceled" :action="newAction" />
    <session-update-component @updated="updatedSession" @canceled="updateSessionCanceled" :sessionToUpdate="sessionToUpdate" />
    <session-delete-component @deleted="deletedSession" @canceled="deleteSessionCanceled" :sessionToDelete="sessionToDelete" />
  </div>
</template>

<script>

import authService from '@/services/authService';

import HomeNavbarComponent from '@/components/navigation/HomeNavbarComponent.vue';
import SessionNavbarComponent from '@/components/session/SessionNavbarComponent.vue';
import SessionListComponent from '@/components/session/SessionListComponent.vue';
import SessionNewComponent from '@/components/session/SessionNewComponent.vue';
import SessionUpdateComponent from '@/components/session/SessionUpdateComponent.vue';
import SessionDeleteComponent from '@/components/session/SessionDeleteComponent.vue';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'HomeView',
  components: {
    'home-navbar-component': HomeNavbarComponent,
    'session-navbar-component': SessionNavbarComponent,
    'session-list-component': SessionListComponent,
    'session-new-component': SessionNewComponent,
    'session-update-component': SessionUpdateComponent,
    'session-delete-component': SessionDeleteComponent
    
  },
  data: function () {
    return {
      sessionToDelete: null,
      sessionToUpdate: null,
      newAction: false,
      listAction: false,
      superadmin: false,
      state: "home"
    }
  },
  mounted () {
    
    this.isSuperAdmin();
    
  },
  methods: {
    isSuperAdmin() {

      authService.isSuperAdmin().then(
        response => {
          this.superadmin = true;
        },
        error => {
          this.superadmin = false;
        }
      );
    },
    loadSessions()
    {
      this.eventDispatcher.emit(eventConfig.RELOAD_SESSION);
    },
    createSession()
    {
      this.newAction = true;
    },
    createSessionCanceled()
    {
      this.newAction = false;
    },
    createdSession()
    {
      this.newAction = false;
      this.loadSessions();
    },
    updateSession(session)
    {
      this.sessionToUpdate = session;
    },
    updateSessionCanceled()
    {
      this.sessionToUpdate = null;
    },
    updatedSession()
    {
      this.sessionToUpdate = null;
      this.loadSessions();
    },
    deleteSession(session)
    {
      this.sessionToDelete = session;
    },
    deleteSessionCanceled()
    {
      this.sessionToDelete = null;
    },
    deletedSession()
    {
      this.sessionToDelete = null;
      this.loadSessions();
    },
    showSession(session)
    {

    },    
    manageGroupsSession(session)
    {

    }
  }

}
</script>



<style scoped lang="scss">

.session {
  padding: 10px;
  padding-left: 40px;
}

h6, .session .name {
  line-height: 40px;
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.actions a span {
  display: none;
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}

.actions a.groups {
  background-image: url(@/assets/images/ico-group.svg);
}

.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>