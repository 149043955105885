import axios from 'axios';
import cookieService from './cookieService';
import apiService from './apiService';
import languageService from './languageService';

export default class authService {

    static signOut () {
        cookieService.removeToken();
    }


    static isUserAuth () {
        
        return new Promise((resolve, reject) => {
            apiService.get("/user/isAuth")
            .then(response => {
                
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(function (error) {
                console.log(error);
                reject(error);
            });
         });
       
    }

    static isSuperAdmin () {
        
        return new Promise((resolve, reject) => {
            apiService.get("/user/isSuperAdmin")
            .then(response => {
                
                
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(function (error) {
                
                reject(error);
            });
         });
       
    }    


    static signin (email, password) {

        let data = {
            email: email,
            password: password
        }; 
        return new Promise((resolve, reject) => {
            apiService.post("/user/signin", data, false)
            .then(response => {
                
                
                if(response.data.language)
                {
                    languageService.setLanguage(response.data.language);    
                }

                if(response.data.token)
                {
                    cookieService.setToken(response.data.token);
                }

                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
         });
    
        
    }


}
