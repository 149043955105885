<template>
  <div id="page" class="p-0 h-100">
    <dashboard-navbar-component :session="session" :superadmin="superadmin" :state="state" />
    <voters-groups-component :superadmin="superadmin" :session="session" />
  </div>
</template>


<script>

import DashboardNavbarComponent from "@/components/dashboard/DashboardNavbarComponent.vue";
import VotersGroupsComponent from "@/components/votersgroups/VotersGroupsComponent.vue";
import authService from '@/services/authService';
import sessionService from '@/services/sessionService';
import eventConfig from '@/configs/eventConfig';
import privilegeConfig from '@/configs/privilegeConfig';

export default {
  name: 'VotersGroupsView',
  data: function () {
    return {
      session: null,
      superadmin: false,
      state: 'votersgroups'
    }
  },
  params: {
    id: String
  },
  components: {
    'dashboard-navbar-component': DashboardNavbarComponent,
    'voters-groups-component': VotersGroupsComponent,
  },
  mounted () {
    
    this.isSuperAdmin();
    
  },
  methods: {

    isSuperAdmin() {
      authService.isSuperAdmin().then(
        response => {
          this.superadmin = true;
          this.loadSession();
        },
        error => {
          this.superadmin = false;
          this.loadSession();
        }
      );
    },
    loadSession()
    {
      
      sessionService.getSession(this.$route.params.id).then(
        response => {
          var session = response;
          
            if(this.superadmin || session.privileges.includes(privilegeConfig.MANAGE_SESSION))
            {
              session.managingSessionAllowed = true;
              
            }

            if(this.superadmin || session.privileges.includes(privilegeConfig.READ_GROUP))
            {
              session.readingGroupAllowed = true;
            }else
            {
              session.readingGroupAllowed = false;
            }
          
          this.session = session;
        },
        errors => {
          
        }
      );
    }
  }
}
</script>