<template>
  <nav v-if="session" class="nav-main navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
    <div class="container-fluid">
      <router-link to="/">
        <img src="../../assets/images/ico-back.svg" width="28" height="28" alt="">
      </router-link>
      <div class="session-title">{{session.name}}</div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarContent">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 dashboard" :class="{'active':state=='dashboard'}">
            <router-link class="nav-link" v-if="readingGroupAllowed || readingVoterAllowed" :to="{ name: 'Dashboard', params: { id: session.id } }">
              <span>Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item mt-2 mt-sm-0 me-sm-3 groups" :class="{'active':state=='votersgroups'}">
            
            <router-link class="nav-link" v-if="readingGroupAllowed || readingVoterAllowed" :to="{ name: 'VotersGroups', params: { id: session.id } }">
              <span>Groups</span>
            </router-link>
          </li>

          <SignoutComponent />
          
        </ul>
        
      </div>
    </div>
  </nav>
</template>

<script>
import SignoutComponent from '@/components/navigation/SignoutComponent.vue';
import eventConfig from '@/configs/eventConfig';
import privilegeConfig from '@/configs/privilegeConfig';

export default {
  name: 'DashboardNavbarComponent',
    props: {
      session: Object,
      superadmin: Boolean,
      state: String
    },
  components: {
    SignoutComponent
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
        this.setPrivileges();
      }
    }
  },
  data: function () {
    return {
      eventConfig: eventConfig,
      readingGroupAllowed: false,
      readingVoterAllowed: false
    }
  },
  methods: {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_GROUP)) )
      {
        this.readingGroupAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_VOTER)) )
      {
        this.readingVoterAllowed = true;
      }

    }
  }

}
</script>

<style scoped lang="scss">
@import '@/sass/variables.scss';

.session-title {
  color: $light;
  margin-left: 20px;
}
</style>