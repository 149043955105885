<template>
  <div class="preview" :class="{'mobile-active': mobileActive}">
    <a class="toggle-mobile" @click="toggleMobile()"></a>
    <div class="channels">
      <select class="form-select form-select-sm" v-model="previewChannel" @change="selectChannel">
            <option v-for="channel in channels" v-bind:key="channel.id" v-bind:value="channel.slug">{{ channel.name }}</option>
      </select>
    </div> 
    <div class="device">
      <iframe :src="previewUrl"></iframe>
    </div>
  </div>
  
  
</template>


<script>

import eventConfig from '@/configs/eventConfig';
import channelService from '@/services/channelService';
import $ from 'jquery';

export default {
  name: 'PreviewComponent',
  props: {
    session: Object
  },
  data: function () {
    return {
      previewUrl: null,
      mobileActive: true,
      channels: null,
      previewChannel: null
    }
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
        this.loadChannels();
      }
    }
  },
  mounted () {

    
    this.loadChannels();
    
    this.eventDispatcher.on(eventConfig.RELOAD_CHANNELS, () => {
        this.loadChannels();
    });

    setTimeout(() => {
      this.resize();  
    }, 300);

    $(window).resize(()=>{
      $(".preview").css('max-height', "0px" );
      this.resize();
    });
    
  },
  methods: 
  {
    loadChannels() {  

      channelService.getList(this.session).then(
        response => {
          

          this.channels = response.channels;

          if(this.channels && !this.previewChannel)
          {
            if(this.channels[0])
            {
              this.previewChannel = this.channels[0].slug;
              this.selectChannel();
            }
            
          }
          
          
        },
        errors => {
          
        }
      );
    },
    selectChannel()
    {
      this.previewUrl = process.env.VUE_APP_PUBLIC_URL+"/"+this.session.slug+"/"+this.previewChannel;
      
    },
    toggleMobile() {
      this.mobileActive = !this.mobileActive;
    },
    resize() {
      var previewContainer = $("#previewContainer").height();
      $(".preview").css('max-height', (previewContainer)+"px" );
    }
  }
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;
  max-height: 0px;
  overflow-y: scroll;
  padding-top: 20px;
  padding-bottom: 20px;
}

.device {
  width: 100%;
  height: 100%;
}

.mobile-active .device {
  background-image: url(@/assets/images/iphone.png);
  background-repeat: no-repeat;
  width: 432px;
  height: 870px;
  margin: auto;
}

.toggle-mobile {
  width: 40px;
  height: 40px;
  background-image: url(@/assets/images/ico-mobile.svg);
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  margin-top: -10px;
  margin-left: 5px;
}

.mobile-active .toggle-mobile {
  background-image: url(@/assets/images/ico-mobile-on.svg);
}

iframe {
  width: 100%;
  height: 100%;
}

.mobile-active iframe {
  width: 375px;
  height: 812px;
  margin-top: 29px;
  margin-left: 29px;
  border-radius: 37px;
  background-color: white;
}

.channels {
  width: 100px;
  position: absolute;
  margin-top: -10px;
  margin-left: 50px;
}

.channels select {
  height: 30px;
}
</style>