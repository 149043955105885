<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a class="nav-link mb-2" @click="createTemplateGroup()">
            <img src="@/assets/images/ico-add.svg" alt="Add template group">
        </a>
      </div>
  </nav>
  <div class="row m-0 flex-grow-1">
    <div class="col col-12 p-0">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t("templateGroups.list.code") }}</th>
            <th scope="col">{{ $t("templateGroups.list.name") }}</th>
            <th scope="col">{{ $t("templateGroups.list.templates") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="templateGroup in templateGroups" v-bind:key="templateGroup.id">
            <td>{{templateGroup.code}}</td>
            <td>{{templateGroup.name}}</td>
            <td>
              <label>{{ $t("templateGroups.list.present_templates_label") }}</label>
              <fieldset class="scheduler-border mt-2 mb-4">
                <small v-for="(template, i) in templateGroup.presentTemplates" :key="i"><i>{{template.name}}</i><br></small>
              </fieldset>

              <label>{{ $t("templateGroups.list.vote_templates_label") }}</label>
              <fieldset class="scheduler-border mt-2 mb-4">
                <small v-for="(template, i) in templateGroup.voteTemplates" :key="i"><i>{{template.name}}</i><br></small>
              </fieldset>

              <label>{{ $t("templateGroups.list.result_templates_label") }}</label>
              <fieldset class="scheduler-border mt-2 mb-4">
                <small v-for="(template, i) in templateGroup.resultTemplates" :key="i"><i>{{template.name}}</i><br></small>
              </fieldset>  
              
              <label>{{ $t("templateGroups.list.screen_templates_label") }}</label>
              <fieldset class="scheduler-border mt-2 mb-4">
                <small v-for="(template, i) in templateGroup.screenTemplates" :key="i"><i>{{template.name}}</i><br></small>
              </fieldset>  
              
              <label>{{ $t("templateGroups.list.qas_templates_label") }}</label>
              <fieldset class="scheduler-border mt-2 mb-4">
                <small v-for="(template, i) in templateGroup.qasTemplates" :key="i"><i>{{template.name}}</i><br></small>
              </fieldset>                
              
              
            </td>
            
            <td class="actions me-2 text-end">
                
                
                <a class="edit" @click="updateTemplateGroup(templateGroup)"></a>
                <a class="delete" @click="deleteTemplateGroup(templateGroup)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>

  <templateGroup-new-component @created="createdTemplateGroup" @canceled="createTemplateGroupCanceled" :action="newAction" />
  <templateGroup-update-component @updated="updatedTemplateGroup" @canceled="updateTemplateGroupCanceled" :templateGroupToUpdate="templateGroupToUpdate" />
  <templateGroup-delete-component @deleted="deletedTemplateGroup" @canceled="deleteTemplateGroupCanceled" :templateGroupToDelete="templateGroupToDelete" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';


import templateGroupService from '@/services/templateGroupService';
import eventConfig from '@/configs/eventConfig';

import TemplateGroupNewComponent from '../templateGroup/TemplateGroupNewComponent.vue'
import TemplateGroupUpdateComponent from '../templateGroup/TemplateGroupUpdateComponent.vue'
import TemplateGroupDeleteComponent from '../templateGroup/TemplateGroupDeleteComponent.vue'


export default {
  name: 'TemplateGroupsComponent',
  emits: [eventConfig.LOADED],
  props: {
    session: Object,
    superadmin: Boolean,
    loadAction: Boolean,
  },
  data: function () {
    return {
      templateGroups: null,
      eventConfig: eventConfig,
      newAction: false,
      templateGroupToUpdate: null,
      templateGroupToDelete: null,
      templateGroupToDisconnect: null,
      templateGroupToBan: null,
      templateGroupToEditTemplates: null
    }
  },
  components: {
    'templateGroup-new-component': TemplateGroupNewComponent,
    'templateGroup-update-component': TemplateGroupUpdateComponent,
    'templateGroup-delete-component': TemplateGroupDeleteComponent,
  },
  watch: 
  { 
    loadAction: function() {
      if(this.loadAction)
      {
        this.loadTemplateGroups();
      }
    
    }
  },
  mounted () {
    this.loadTemplateGroups();
  },
  methods: 
  {

    loadTemplateGroups() {  
      
      templateGroupService.getListFull().then(
        response => {
          this.templateGroups = response;
          this.$emit(eventConfig.LOADED);
        },
        errors => {
          
        }
      );
    },
    
    createTemplateGroup()
    {
      this.newAction = true;
    },
    createdTemplateGroup(templateGroup)
    {
      this.newAction = false;
      this.loadTemplateGroups();
    },
    createTemplateGroupCanceled()
    {
      this.newAction = false;
    },
   
    updateTemplateGroup(templateGroup)
    {
      this.templateGroupToUpdate = templateGroup;
    },
    updatedTemplateGroup(templateGroup)
    {
      
      this.templateGroupToUpdate = null;
      this.loadTemplateGroups();
    },
    updateTemplateGroupCanceled()
    {
      this.templateGroupToUpdate = null;
    },
    
  
    deleteTemplateGroup(templateGroup)
    {
      this.templateGroupToDelete = templateGroup;
    },
    deleteTemplateGroupCanceled()
    {
      this.templateGroupToDelete = null;
    },
    deletedTemplateGroup()
    {
      this.templateGroupToDelete = null;
      this.loadTemplateGroups();
    },

  }
 
}
</script>

<style scoped lang="scss">

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}


.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}

.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

fieldset.scheduler-border {
    border: 1px solid #ced4da;
    padding: 20px;
    border-radius: 0.25rem;

}


</style>