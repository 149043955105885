import apiService from './apiService';

export default class answerService {


    static getListByQuestion (data) {

        return new Promise((resolve, reject) => {
            
            apiService.post("/answer/listByQuestion", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static checkIfQuestionHaveNotExternalVotes (data) {

        return new Promise((resolve, reject) => {
            
            apiService.post("/answer/checkIfQuestionHaveNotExternalVotes", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static updateAnswers (data) {
        
        return new Promise((resolve, reject) => {
            
            
            apiService.post("/answer/editAll", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }    


}
