<template>
  <nav class="navbar navbar-light bg-light border-bottom">
    <div v-if="readingChannelAllowed" class="container-fluid ps-2 pe-0">
      <h6 class="mt-2"><b>{{ $t("channel.list.title") }}</b></h6>
      <a v-if="managingChannelAllowed" class="nav-link" @click="$emit(eventConfig.NEW)">
        <img src="@/assets/images/ico-add.svg" alt="Add channel">
      </a>
    </div>
  </nav>
</template>

<script>
import eventConfig from '@/configs/eventConfig'
import privilegeConfig from '@/configs/privilegeConfig';
export default {
  name: 'ChannelsNavbarComponent',
  props: {
    superadmin: Boolean,
    session: Object
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
        this.setPrivileges();
      }
    }
  },
  data: function () {
    
    return {
      eventConfig: eventConfig,
      managingChannelAllowed: false,
      readingChannelAllowed: false,
      pilotChannelAllowed: false,
    }
  },
  mounted () {
    
    

  },
  methods: {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_CHANNEL)) )
      {
        this.managingChannelAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_CHANNEL)) )
      {
        this.readingChannelAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_CHANNEL)) )
      {
        this.pilotChannelAllowed = true;
      }
    }
  }
}
</script>
<style scoped>

</style>
