<template>

  <div id="newChannelModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("channel.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("channel.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("channel.new.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="channel.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>
            <div class="form-group mb-3">
              <label for="slugInput">{{ $t("channel.new.slug_label") }}</label>
              <input type="text" class="form-control" :class="{ 'is-invalid': slugError, 'is-valid': slugSuccess }" id="slugInput" v-model="channel.slug" @input="testSlug()">
              <div v-if="slugError" class="invalid-feedback">
                {{slugError}}
              </div>
              <div v-if="slugSuccess" class="valid-feedback">
                {{slugSuccess}}
              </div>
            </div>
     
            
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("channel.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createChannel()">{{ $t("channel.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import channelService from '@/services/channelService';

const emptyChannel = {
       name: null,
       slug: null
     };

export default {
  name: 'ChannelNewComponent',
  props: {
    action: Boolean,
    session: Object
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      channel: emptyChannel,
      newModal: null,
      nameError: null,
      slugError: null,
      slugSuccess: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newChannelModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    resetCurrentChannel()
    {
      this.channel.name = null;
      this.channel.slug = null;
      this.nameError = null;
      this.slugError = null;
      this.slugSuccess = null;
      this.error = null;
    },
    openModal() {
      this.resetCurrentChannel();
      this.newModal.show();
    },
    testSlug()
    {
      this.slugSuccess = null;
      this.slugError = null;
      
      if(this.channel.slug != this.currentSlug)
      {
        channelService.isSlugAvailable(this.channel.slug, this.session).then(
          response => {

            this.slugSuccess = (response.msg_code) ? this.$t(response.msg_code) : response.msg;
            this.slugError = null;
          },
          errors => {
            
              this.slugSuccess = null;
              this.parseErrors(errors.response.data);
            
          }
        );
      }
    },
    createChannel() {
      this.slugSuccess = null;
      this.slugError = null;
      this.nameError = null;

      this.channel.session = this.session.id;
      channelService.create(this.channel).then(
        response => {
          this.newModal.hide();
          this.$emit(eventConfig.CREATED);
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'slug':
              this.slugError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>