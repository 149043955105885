const DocumentSocket = require("./DocumentSocket");
const DocumentSocketType = require("./DocumentSocketType");

class QuestionSocket extends DocumentSocket {
    constructor(documentId, question, answers, status) {
        
        super(documentId, DocumentSocketType.QUESTION, status, {
            question: question,
            answers: answers
        });
    }

}
module.exports = QuestionSocket;