const DocumentSocketStatus = require("./DocumentSocketStatus");
const QuestionSocket = require("./QuestionSocket");



class VoteSocket extends QuestionSocket {
    constructor(documentId, question, answers) {
        
        super(documentId, question, answers, DocumentSocketStatus.VOTE );
    }


}
module.exports = VoteSocket;