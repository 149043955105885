<template>
  <div>
    <channel-navbar-component :superadmin="superadmin" @new="createChannel" :session="session" />
    <channel-list-component :superadmin="superadmin" :session="session" @update="updateChannel" @delete="deleteChannel" />
    <channel-new-component @created="createdChannel" @canceled="createChannelCanceled" :session="session" :action="newAction" />
    <channel-update-component @updated="updatedChannel" @canceled="updateChannelCanceled" :session="session" :channelToUpdate="channelToUpdate" />
    <channel-delete-component @deleted="deletedChannel" @canceled="deleteChannelCanceled" :session="session" :channelToDelete="channelToDelete" />
  </div>
</template>

<script>
import eventConfig from '@/configs/eventConfig'
import ChannelListComponent from './ChannelListComponent.vue'
import ChannelNavbarComponent from './ChannelNavbarComponent.vue'
import ChannelNewComponent from './ChannelNewComponent.vue'
import ChannelUpdateComponent from './ChannelUpdateComponent.vue'
import ChannelDeleteComponent from './ChannelDeleteComponent.vue'


export default {
  name: 'ChannelsComponent',
  props: {
    session: Object,
    superadmin: Boolean
  },
  components: {
    'channel-navbar-component': ChannelNavbarComponent,
    'channel-list-component': ChannelListComponent,
    'channel-new-component': ChannelNewComponent,
    'channel-update-component': ChannelUpdateComponent,
    'channel-delete-component': ChannelDeleteComponent
  },
  data: function () {
    return {
      channelToDelete: null,
      channelToUpdate: null,
      newAction: false
    }
  },  
  methods: {
    loadChannels()
    {
      this.eventDispatcher.emit(eventConfig.RELOAD_CHANNELS);
    },
    createChannel()
    {
      this.newAction = true;
    },
    createChannelCanceled()
    {
      this.newAction = false;
    },
    createdChannel()
    {
      this.newAction = false;
      this.loadChannels();
    },
    updateChannel(channel)
    {
      this.channelToUpdate = channel;
    },
    updateChannelCanceled()
    {
      this.channelToUpdate = null;
    },
    updatedChannel()
    {
      this.channelToUpdate = null;
      this.loadChannels();
    },
    deleteChannel(channel)
    {
      this.channelToDelete = channel;
    },
    deleteChannelCanceled()
    {
      this.channelToDelete = null;
    },
    deletedChannel()
    {
      this.channelToDelete = null;
      this.loadChannels();
    },
  }
}
</script>
