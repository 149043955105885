<template>
  <div id="page" class="h-100">
    <div>
      <img src="@/assets/images/tada-logo.svg" alt="Tada.vote">
      <p class="text">
        {{ $t("page404.message") }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Message404Component'
}
</script>

<style scoped lang="scss">
#page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

p {
  text-align: center;
  margin-top: 30px;
}
</style>