import apiService from './apiService';

export default class groupService {


    static getList (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/group/listBySession/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

   
    static create (group) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/group/new", group)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (group) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/group/edit", group)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (group) {
        
        return new Promise((resolve, reject) => {
            apiService.post("/group/remove", group)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

}
