<template>
  <div class="container-fluid h-100 after-navbar">
    <div class="row h-100">
      <div class="col col-12 p-0 h-100 d-flex flex-column">
              
        <div class="flex-grow-1 d-flex flex-column">
            <nav class="navbar navbar-light bg-light border-bottom">
              <div class="container-fluid p-0 ps-2">
                <ul class="nav nav-tabs">
                  <li v-if="readingVoterAllowed && votersActive" class="nav-item">
                    <a class="nav-link" :class="{'active': currentState=='voters'}" @click="selectVoters()">{{ $t("votersgroups.board.voters_title") }}</a>
                  </li>
                  <li v-if="readingGroupAllowed" class="nav-item">
                    <a class="nav-link" :class="{'active': currentState=='groups'}" @click="selectGroups()">{{ $t("votersgroups.board.groups_title") }}</a>
                  </li>
                </ul>
                
                <a v-if="managingVoterAllowed && managingGroupAllowed" class="nav-link mb-2" @click="importCSV()">
                  <img src="@/assets/images/ico-upload.svg" alt="Import users">
                </a>
              </div>
            </nav>
            
            <voters-component v-if="currentState=='voters' && readingVoterAllowed" @loaded="loadedVoters" :superadmin="superadmin" :session="session" :loadAction="loadAction" />
            <groups-component v-if="currentState=='groups' && readingGroupAllowed" @loaded="loadedVoters" :superadmin="superadmin" :session="session" :loadAction="loadAction" />
              
            <import-voters-component @imported="importedVoters" @canceled="importVotersCanceled" :session="session" :action="importAction" />

        </div>
      

      </div>
    </div>
  </div>
  

</template>

<script>
import VotersComponent from "@/components/voter/VotersComponent.vue";
import GroupsComponent from "@/components/group/GroupsComponent.vue";
import ImportVotersComponent from "@/components/votersgroups/ImportVotersComponent.vue";
import privilegeConfig from '@/configs/privilegeConfig';
import sessionConfig from "@/configs/sessionConfig";

export default {
  name: 'VotersGroupsComponent',
  props: {
    session: Object,
    superadmin: Boolean
  },
  components: {
      'voters-component': VotersComponent,
      'groups-component': GroupsComponent,
      'import-voters-component': ImportVotersComponent
  },
  watch: 
  { 
    session: function() {
      if(this.session)
      {
        this.setPrivileges();
        
        if(this.session.signin == sessionConfig.SIGNIN_PUBLIC)
        {
          this.currentState = 'groups';
        }else
        {
          this.votersActive = true;
        }
      }
    }
  },
  data: function () {
    return {
      currentState: 'voters',
      votersActive: false,
      importAction: false,
      loadAction: false,
      newAction: false,
      userToUpdate: null,
      userToDelete: null,
      readingVoterAllowed: false,
      readingGroupAllowed: false,
      managingVoterAllowed: false,
      managingGroupAllowed: false,
    }
  },
  mounted () {
    

  },
  methods: {
    setPrivileges()
    {

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_GROUP)) )
      {
        this.readingGroupAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_VOTER)) )
      {
        this.readingVoterAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_GROUP)) )
      {
        this.managingGroupAllowed = true;
      }

      if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_VOTER)) )
      {
        this.managingVoterAllowed = true;
      }

      if(!this.readingVoterAllowed && this.readingGroupAllowed)
      {
        this.currentState = 'groups';
      }

    },
    importCSV()
    {
      this.importAction = true;
    },
    selectVoters()
    {
      this.currentState = 'voters';
    },
    selectGroups()
    {
      this.currentState = 'groups';
    },
    importedVoters()
    {
      this.loadAction = true;
      this.importAction = false;
    },
    importVotersCanceled()
    {
      this.importAction = false;
    },
    loadedVoters()
    {
      this.loadAction = false;
    }
  }
}
</script>
