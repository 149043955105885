<template>

  <div id="newQaModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("qas.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("qas.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="textInput">{{ $t("qas.new.text_label") }}</label>
              <input id="textInput" type="text" class="form-control" :class="{ 'is-invalid': textError}" v-model="qa.text">      
              <div v-if="textError" class="invalid-feedback">
                {{textError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="firstNameInput">{{ $t("qas.new.firstName_label") }}</label>
              <input id="firstNameInput" type="text" class="form-control" :class="{ 'is-invalid': firstNameError}" v-model="qa.firstName">      
              <div v-if="firstNameError" class="invalid-feedback">
                {{firstNameError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="lastNameInput">{{ $t("qas.new.lastName_label") }}</label>
              <input id="lastNameInput" type="text" class="form-control" :class="{ 'is-invalid': lastNameError}" v-model="qa.lastName">      
              <div v-if="lastNameError" class="invalid-feedback">
                {{lastNameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="displayNameNew" v-model="qa.displayName">
                <label class="form-check-label" for="displayNameNew">{{ $t("qas.new.display_name_label") }}</label>
              </div>
            </div>  

     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("qas.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createQa()">{{ $t("qas.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import qaService from '@/services/qaService';

const emptyQa = {
       text: null,
       firstName: null,
       lastName: null,
       displayName: false
     };

export default {
  name: 'QaNewComponent',
  props: {
    action: Boolean,
    session: Object
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {

          this.openModal();
        
      }
    }
  },
  data: function () {
    return {
      qa: emptyQa,
      newModal: null,
      textError: null,
      firstNameError: null,
      lastNameError: null,
      error: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newQaModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      
      this.qa = JSON.parse(JSON.stringify(emptyQa));
      this.newModal.show();
    },
    createQa() {
      
      this.textError = null;
      this.firstNameError = null;
      this.lastNameError = null;

      this.qa.session = this.session.id;
      
      
      qaService.create(this.qa).then(
        response => {
          this.newModal.hide();
          this.$emit(eventConfig.CREATED, response);
        },
        errors => {
            this.parseErrors(errors.response.data);
        }
      );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'text':
              this.textError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'firstName':
              this.firstNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'lastName':
              this.lastNameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>
<style scoped>
</style>