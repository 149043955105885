<template>

  <div id="newTemplateGroupModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("templateGroups.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("templateGroups.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="codeInput">{{ $t("templateGroups.new.code_label") }}</label>
              <input id="codeInput" type="text" class="form-control" :class="{ 'is-invalid': codeError}" v-model="templateGroup.code">      
              <div v-if="codeError" class="invalid-feedback">
                {{codeError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("templateGroups.new.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="templateGroup.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label>{{ $t("templateGroups.new.present_templates_label") }}</label>
              <fieldset class="scheduler-border">
                <div class="form-check" v-for="(template, index) in presentTemplates" v-bind:key="template.id" >
                  <input class="form-check-input" type="checkbox" :id="'ppg'+index" :value="template.id" v-model="templateGroup.presentTemplates">
                  <label class="form-check-label" :for="'ppg'+index">
                    {{ template.name }}
                  </label>
                </div>
              </fieldset>
              <div v-if="presentTemplatesError" class="alert alert-danger mt-2" role="alert">
                {{presentTemplatesError}}
              </div>  
            </div>

            <div class="form-group mb-3">
              <label>{{ $t("templateGroups.new.vote_templates_label") }}</label>
              <fieldset class="scheduler-border">
                <div class="form-check" v-for="(template, index) in voteTemplates" v-bind:key="template.id" >
                  <input class="form-check-input" type="checkbox" :id="'vpg'+index" :value="template.id" v-model="templateGroup.voteTemplates">
                  <label class="form-check-label" :for="'vpg'+index">
                    {{ template.name }}
                  </label>
                </div>
              </fieldset>
              <div v-if="voteTemplatesError" class="alert alert-danger mt-2" role="alert">
                {{voteTemplatesError}}
              </div>  
            </div>   

            <div class="form-group mb-3">
              <label>{{ $t("templateGroups.new.result_templates_label") }}</label>
              <fieldset class="scheduler-border">
                <div class="form-check" v-for="(template, index) in resultTemplates" v-bind:key="template.id" >
                  <input class="form-check-input" type="checkbox" :id="'rpg'+index" :value="template.id" v-model="templateGroup.resultTemplates">
                  <label class="form-check-label" :for="'rpg'+index">
                    {{ template.name }}
                  </label>
                </div>
              </fieldset>
              <div v-if="resultTemplatesError" class="alert alert-danger mt-2" role="alert">
                {{resultTemplatesError}}
              </div>  
            </div>  
            
            <div class="form-group mb-3">
              <label>{{ $t("templateGroups.new.screen_templates_label") }}</label>
              <fieldset class="scheduler-border">
                <div class="form-check" v-for="(template, index) in screenTemplates" v-bind:key="template.id" >
                  <input class="form-check-input" type="checkbox" :id="'epg'+index" :value="template.id" v-model="templateGroup.screenTemplates">
                  <label class="form-check-label" :for="'epg'+index">
                    {{ template.name }}
                  </label>
                </div>
              </fieldset>
              <div v-if="screenTemplatesError" class="alert alert-danger mt-2" role="alert">
                {{screenTemplatesError}}
              </div>  
            </div>   
            
            
            <div class="form-group mb-3">
              <label>{{ $t("templateGroups.new.qas_templates_label") }}</label>
              <fieldset class="scheduler-border">
                <div class="form-check" v-for="(template, index) in qasTemplates" v-bind:key="template.id" >
                  <input class="form-check-input" type="checkbox" :id="'qpg'+index" :value="template.id" v-model="templateGroup.qasTemplates">
                  <label class="form-check-label" :for="'qpg'+index">
                    {{ template.name }}
                  </label>
                </div>
              </fieldset>
              <div v-if="qasTemplatesError" class="alert alert-danger mt-2" role="alert">
                {{qasTemplatesError}}
              </div>  
            </div>   


     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("templateGroups.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createTemplateGroup()">{{ $t("templateGroups.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import templateGroupService from '@/services/templateGroupService';
import templateService from '@/services/templateService';
import groupService from '@/services/groupService';
import { looseIndexOf } from '@vue/shared';

const emptyTemplateGroup = {
      code: null,
      name: null,
      presentTemplates: [],
      voteTemplates: [],
      resultTemplates: [],
      screenTemplates: [],
      qasTemplates: []
     };

export default {
  name: 'TemplateGroupNewComponent',
  props: {
    action: Boolean
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
    
        Promise.all([this.loadTemplatePresents(), this.loadTemplateVotes(), this.loadTemplateResults(), this.loadTemplateScreens(), this.loadTemplateQas()]).then(() => {
          this.resetErrors();
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      templateGroup: emptyTemplateGroup,
      newModal: null,
      codeError: null,
      nameError: null,
      presentTemplatesError: null,
      voteTemplatesError: null,
      resultTemplatesError: null,
      screenTemplatesError: null,
      qasTemplatesError: null,
      error: null,
      presentTemplates: null,
      voteTemplates: null,
      resultTemplates: null,
      screenTemplates: null,
      qasTemplates: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newTemplateGroupModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    loadTemplatePresents()
    {
      templateService.getListPresent().then(
        response => {
          this.presentTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    },
    loadTemplateVotes()
    {
      templateService.getListVote().then(
        response => {
          this.voteTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    },
    loadTemplateResults()
    {
      templateService.getListResult().then(
        response => {
          this.resultTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    }, 
    loadTemplateScreens()
    {
      templateService.getListScreen().then(
        response => {
          this.screenTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    }, 
    loadTemplateQas()
    {
      templateService.getListQas().then(
        response => {
          this.qasTemplates = response;
          
        },
        errors => {
          
        }
      );
      
    },   
    resetErrors()
    {
      this.codeError = null;
      this.nameError = null;
      this.error = null;
      this.presentTemplatesError = null;
      this.voteTemplatesError = null;
      this.resultTemplatesError = null;
      this.screenTemplatesError = null;
      this.qasTemplatesError = null;
    },
    
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      
      this.templateGroup = JSON.parse(JSON.stringify(emptyTemplateGroup));
      this.newModal.show();
    },
    
    createTemplateGroup() {
      
      this.resetErrors();

        templateGroupService.create(this.templateGroup).then(
          response => {
            console.log("templateGroupService created");
            this.newModal.hide();
            this.$emit(eventConfig.CREATED, response);
          },
          errors => {
            console.log(errors);
              this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'code':
              this.codeError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            default:
              
              if(error.param.indexOf('presentTemplates') > -1)
              {
                this.presentTemplatesError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
              }else if(error.param.indexOf('voteTemplates') > -1)
              {
                this.voteTemplatesError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
              }else if(error.param.indexOf('resultTemplates') > -1)
              {
                this.resultTemplatesError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
              }else if(error.param.indexOf('screenTemplates') > -1)
              {
                this.screenTemplatesError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
              }else if(error.param.indexOf('qasTemplates') > -1)
              {
                this.qasTemplatesError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
              }
              
            break;
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>
<style scoped>
fieldset.scheduler-border {
    border: 1px solid #ced4da;
    padding: 20px;
    border-radius: 0.25rem;

}


</style>