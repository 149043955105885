<template>

<div id="deleteStreamModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ $t("stream.delete.title") }}</h5>
        <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{ $t("stream.delete.absolutely_sure") }}</p>
        <p v-if="stream" v-html="$t('stream.delete.action', {name: stream.name})"></p>
        <p v-if="stream" v-html="$t('stream.delete.actionName', {name: stream.name})"></p>
        <div class="form-group mb-3">
            <label for="slugInput">{{ $t("stream.delete.stream_name") }}</label>
            <input type="text" class="form-control" id="slugInput" v-model="deleteName" :class="{ 'is-invalid': deleteNameError}" @input="deleteNameError = null" @click.right.prevent @copy.prevent @paste.prevent>
            <div v-if="deleteNameError" class="invalid-feedback">
              {{deleteNameError}}
            </div>
        </div>

        <div v-if="error" class="alert alert-danger" role="alert">
          {{error}}
        </div>        
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-danger" @click="removeStream()">{{ $t("stream.delete.understand_consequences_label") }}</button>
        <button type="button" class="btn btn-primary" @click="cancel()">{{ $t("stream.delete.cancel_label") }}</button>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import streamService from '@/services/streamService';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'StreamDeleteComponent',
  props: {
    streamToDelete: Object,
    session: Object
  },
  watch: 
  { 
    streamToDelete: function() {
      if(this.streamToDelete)
      {
        this.stream = JSON.parse(JSON.stringify(this.streamToDelete));
        this.openModal();
      }
    }
  },
  data: function () {
    return {
      stream: null,
      error: null,
      deleteName: null,
      deleteNameError: null,
      deleteModal: null
    }
  },
  components: {
    
  },
  mounted () {
    this.deleteModal = new Modal($('#deleteStreamModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    cancel()
    {
      this.deleteModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal()
    {
      this.deleteName = null;
      this.deleteNameError = null;
      this.error = null;
      this.deleteModal.show();
    },
    removeStream()
    {
      if(this.deleteName === this.stream.name)
      {
        const data = {
          id: this.stream.id,
          session: this.session.id
        };

        streamService.delete(data).then(
          response => {
            this.deleteModal.hide();
            this.$emit(eventConfig.DELETED);
          },
          errors => {
            this.parseErrors(errors.response.data);
          }
        );
      }else
      {
        this.deleteNameError = this.$t("stream.delete.name_doesnt_match");
      }
    },
    parseErrors(data)
    {
      console.log(data);
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>