<template>
  <div class="flex-grow-1 d-flex flex-column">
    <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid p-0 ps-2">
        <ul v-if="readingStreamAllowed" class="nav nav-tabs">
          <li class="nav-item" v-for="stream in streams" v-bind:key="stream.id">
            <a class="nav-link" :class="{'active': (currentStream && stream.id == currentStream.id)}" @click="selectStream(stream)">{{ stream.name }}</a>
          </li>
        </ul>
        
        <a v-if="managingStreamAllowed" class="nav-link mb-2" @click="createStream()">
            <img src="@/assets/images/ico-add.svg" alt="Add stream">
        </a>
      </div>
    </nav>
    <nav v-if="currentStream" class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid p-0 ps-2">

        <ul class="nav">
          <li v-if="readingQuestionAllowed" class="nav-item">
            <a class="nav-link" :class="{'active': currentState=='polls'}" @click="selectPolls()">{{ $t("stream.menu.polls") }}</a>
          </li>
          <li v-if="session.qasActivated && readingQasAllowed" class="nav-item">
            <a class="nav-link" :class="{'active': currentState=='q&a'}" @click="selectQA()">{{ $t("stream.menu.qa") }}</a>
          </li>
          <li v-if="readingScreenAllowed" class="nav-item">
            <a class="nav-link" :class="{'active': currentState=='screens'}" @click="selectScreens()">{{ $t("stream.menu.screens") }}</a>
          </li>
          
        </ul>

        <div class="actions me-2">
          <a v-if="managingStreamAllowed" class="edit" @click="updateStream()"></a>
          <a v-if="managingStreamAllowed" class="delete" @click="deleteStream()"></a>
        </div>

      </div>
    </nav>


    <div class="row m-0 flex-grow-1">
      <div id="streamContainer" class="col col-12 col-lg-6 p-0 border-right">

        

        <questions-component v-if="currentState=='polls' && this.currentStream" :session="session" :stream="currentStream" :superadmin="superadmin" @socketChange="socketChange" />
        <qas-component v-if="currentState=='q&a' && this.currentStream" :session="session" :stream="currentStream" :superadmin="superadmin" @socketChange="socketChange" />
        <screens-component v-if="currentState=='screens' && this.currentStream" :session="session" :stream="currentStream" :superadmin="superadmin" @socketChange="socketChange" />

      </div>
      <div id="previewContainer" class="col col-12 col-lg-6 p-0">
        <preview-component :session="session" />
      </div>
    </div>

</div>

<stream-new-component @created="createdStream" @canceled="createStreamCanceled" :session="session" :action="newAction" />
<stream-update-component @updated="updatedStream" @canceled="updateStreamCanceled" :session="session" :streamToUpdate="streamToUpdate" />
<stream-delete-component @deleted="deletedStream" @canceled="deleteStreamCanceled" :session="session" :streamToDelete="streamToDelete" />

</template>

<script>
import PreviewComponent from '../preview/PreviewComponent.vue'
import QasComponent from '../qa/QasComponent.vue'
import QuestionsComponent from '../question/QuestionsComponent.vue'
import ScreensComponent from '../screen/ScreensComponent.vue'
import StreamNewComponent from '../stream/StreamNewComponent.vue'
import StreamUpdateComponent from '../stream/StreamUpdateComponent.vue'
import StreamDeleteComponent from '../stream/StreamDeleteComponent.vue'

import streamService from '@/services/streamService';
import privilegeConfig from '@/configs/privilegeConfig';
import eventConfig from '@/configs/eventConfig';

export default {
  name: 'StreamsComponent',
  props: {
    session: Object,
    superadmin: Boolean
  },
  data: function () {
    return {
      streams: null,
      currentStream: null,
      currentState: null,
      newAction: false,
      loadAction: false,
      streamToUpdate: null,
      streamToDelete: null,
      managingStreamAllowed: false,
      pilotingStreamAllowed: false,
      readingStreamAllowed: false,
      readingQuestionAllowed: false,
      readingQasAllowed: false,
      readingScreenAllowed: false
    }
  },
  watch: 
  { 
    session: function() {

      if(this.session)
      {
        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_STREAM)) )
        {
          this.readingStreamAllowed = true;
        }

        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_STREAM)) )
        {
          this.managingStreamAllowed = true;
        }

        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_STREAM)) )
        {
          this.pilotingStreamAllowed = true;
        }

        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_QUESTION)) )
        {
          this.readingQuestionAllowed = true;
        }

        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_QAS)) )
        {
          this.readingQasAllowed = true;
        }

        if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_SCREEN)) )
        {
          this.readingScreenAllowed = true;
        }

        this.loadStreams();
      }
    }
  },
  mounted () {
    this.loadStreams();
    this.eventDispatcher.on(eventConfig.RELOAD_STREAMS, () => {
      this.loadStreams();
    });

  },
  components: {
    'preview-component': PreviewComponent,
    'stream-new-component': StreamNewComponent,
    'stream-update-component': StreamUpdateComponent,
    'stream-delete-component': StreamDeleteComponent,
    'questions-component': QuestionsComponent,
    'qas-component': QasComponent,
    'screens-component': ScreensComponent
  },
  methods: {
    loadStreams() {  

      
      streamService.getList(this.session).then(
        response => {
          
          var streams = response;
          
          
          for(var i=0; i<streams.length; i++)
          {
            

            if(this.currentStream && this.currentStream.id == streams[i].id)
            {
              this.selectStream(streams[i]);
            }

          }

          this.streams = streams;

          if(!this.currentStream && this.streams.length>0)
          {
            this.selectStream(this.streams[0]);
            
          }

          

          this.$emit(eventConfig.LOADED);
          
        },
        errors => {
          
        }
      );
    },
    selectPolls()
    {
      this.currentState = 'polls';
    },
    selectQA()
    {
      this.currentState = 'q&a';
    },
    selectScreens()
    {
      this.currentState = 'screens';
    },
    selectStream(stream)
    {
      if( (this.currentStream && stream.id != this.currentStream.id) || !this.currentStream)
      {
        this.currentState = null;
        
      }
      this.currentStream = stream;

      var nextState;

      if(this.readingQuestionAllowed)
      {
        nextState = 'polls';
      }

      if(this.currentState != null)
      {
        nextState = this.currentState;
      }

      this.currentState = null;
      setTimeout(()=>{
        this.currentState = nextState;
      },300);
      
      
    },
    createStream()
    {
      this.newAction = true;
    },
    updateStream()
    {
      this.streamToUpdate = this.currentStream;
    },
    createStreamCanceled()
    {
      this.newAction = false;
    },
    createdStream(stream)
    {
      
      this.currentStream = stream;

      this.newAction = false;
      this.loadStreams();


      this.eventDispatcher.emit(eventConfig.RELOAD_STREAMS);
    },
    updatedStream(stream)
    {
      
      this.streamToUpdate = null;
      this.loadStreams();

      this.eventDispatcher.emit(eventConfig.RELOAD_STREAMS);
    },
    updateStreamCanceled()
    {
      this.streamToUpdate = null;
    },    
    deleteStream()
    {
      this.streamToDelete = this.currentStream;
    },
    deleteStreamCanceled()
    {
      this.streamToDelete = null;
    },
    deletedStream()
    {
      
      this.currentStream = null;
      this.streamToDelete = null;
      this.loadStreams();

      this.eventDispatcher.emit(eventConfig.RELOAD_STREAMS);
    },
    socketChange(documentSocket)
    {
      
      var data = {
        id: this.currentStream.id,
        documentSocket: documentSocket.toJSON(),
        session: this.session.id
      }
      
      streamService.updateDocumentSocket(data).then(
        response => {
          
          this.eventDispatcher.emit(eventConfig.STREAM_CHANGE, {stream: this.currentStream, documentSocket: documentSocket} );
          this.loadStreams();
        },
        errors => {
          
        }
      );
      
    }
    
  }
}
</script>

<style scoped lang="scss">

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}

.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>