import apiService from './apiService';

export default class questionService {


    static getList (question) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/question/listBySession/"+question.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static reorder (questionIds) {
        
        return new Promise((resolve, reject) => {
            const data = {
                questionIds: questionIds
            }
            apiService.post("/question/reorder/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static create (question) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/question/new", question)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (question) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/question/edit", question)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (data) {

        return new Promise((resolve, reject) => {
            apiService.post("/question/remove", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


    static eraseVotes (data) {

        return new Promise((resolve, reject) => {
            apiService.post("/question/eraseVotes", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      


}
