<template>
  <div class="container-fluid after-two-navbar">
    <div class="row session border-bottom" v-for="session in sessions" v-bind:key="session.id">
      <div class="col name"><router-link :to="{ name: 'Dashboard', params: { id: session.id } }">{{ session.name }}</router-link></div>
      <div class="col name">{{ session.slug }}</div>
      <div class="col available d-flex justify-content-center">
        <div class="name availability" :class="{ disabled: !session.enabled }"></div>
        <div v-if="!isSessionDelayed(session) && session.startDate && session.endDate" class="name delay now" :title="$t('session.list.session_delayed_date', {startDateYear: moment(session.startDate, 'YYYY'), startDateMonth: moment(session.startDate, 'MM'), startDateDay: moment(session.startDate, 'DD'), startDateTime: moment(session.startDate, 'HH:mm:ss'), endDateYear: moment(session.endDate, 'YYYY'), endDateMonth: moment(session.endDate, 'MM'), endDateDay: moment(session.endDate, 'DD'), endDateTime: moment(session.endDate, 'HH:mm:ss')})"></div>
        <div v-if="isSessionDelayed(session)" class="name delay delayed" :title="$t('session.list.session_delayed_date', {startDateYear: moment(session.startDate, 'YYYY'), startDateMonth: moment(session.startDate, 'MM'), startDateDay: moment(session.startDate, 'DD'), startDateTime: moment(session.startDate, 'HH:mm:ss'), endDateYear: moment(session.endDate, 'YYYY'), endDateMonth: moment(session.endDate, 'MM'), endDateDay: moment(session.endDate, 'DD'), endDateTime: moment(session.endDate, 'HH:mm:ss')})"></div>
        <div v-if="!isSessionDelayed(session) && !session.startDate && !session.endDate" class="name delay"></div>
      </div>
      <div class="col actions d-flex justify-content-end">
        <a v-if="session.managingSessionAllowed" class="edit" @click="$emit(eventConfig.UPDATE, session)">
          <span>Edit</span>
        </a>
        <a v-if="superadmin" class="delete" @click="$emit(eventConfig.DELETE, session)">
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import sessionService from '../../services/sessionService';
import privilegeConfig from '@/configs/privilegeConfig';
import eventConfig from '@/configs/eventConfig';
import moment from "moment";


export default {
  name: 'SessionListComponent',
  props: {
    superadmin: Boolean
  },
  filters: {
    moment: function (date, format) {
      return moment(date).format(format);
    }
  },
  data: function () {
    return {
      sessions: null,
      eventConfig: eventConfig
    }
  },
  components: {
    
  },
  watch: 
  { 
    superadmin: function() {
      if(typeof this.superadmin != 'undefined')
      {
          this.loadSessions();
      }
    }
  },
  mounted () {
    this.loadSessions();
    this.eventDispatcher.on(eventConfig.RELOAD_SESSION, () => {
      this.loadSessions();
    });

  },
  methods: {
    moment (date, format) {
      return moment(date).format(format);
    },
    isSessionDelayed(session) {
      
      if(!session.startDate && !session.endDate)
      {
        return false;
      }

      if(session.startDate && (moment(session.startDate) > moment()))
      {
        return true;
      }

      if(session.endDate && (moment(session.endDate) < moment()))
      {
        return true;
      }

      return false;

    },
    loadSessions() {  
      sessionService.getList().then(
        response => {
          var sessions = response;
          
          for(var i=0; i<sessions.length; i++)
          {

            if(this.superadmin || (sessions[i].privileges && sessions[i].privileges.includes(privilegeConfig.MANAGE_SESSION)) )
            {
              sessions[i].managingSessionAllowed = true;
            }else
            {
              sessions[i].managingSessionAllowed = false;
            }

            if(this.superadmin || (sessions[i].privileges && sessions[i].privileges.includes(privilegeConfig.READ_GROUP)) )
            {
              sessions[i].readingGroupAllowed = true;
            }else
            {
              sessions[i].readingGroupAllowed = false;
            }
          }
          this.sessions = sessions;
        },
        errors => {
          
        }
      );
    }
  }
}
</script>

<style scoped lang="scss">

.session {
  padding: 10px;
  padding-left: 40px;
}

h6, .session .name {
  line-height: 40px;
}

.available .availability {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url(../../assets/images/ico-enabled.svg);
}


.available .delay {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
  
}

.available .delay.now {
  background-image: url(../../assets/images/ico-now.svg);
}

.available .delay.delayed {
  background-image: url(../../assets/images/ico-delayed.svg);
}

.available .availability.disabled {
  background-image: url(../../assets/images/ico-disabled.svg);
}

.actions a {
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.actions a span {
  display: none;
}

.actions a.edit {
  background-image: url(../../assets/images/ico-edit.svg);
}

.actions a.groups {
  background-image: url(../../assets/images/ico-group.svg);
}

.actions a.delete {
  background-image: url(../../assets/images/ico-trash.svg);
}
</style>