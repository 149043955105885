<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a class="nav-link mb-2" @click="createPrivilegeGroup()">
            <img src="@/assets/images/ico-add.svg" alt="Add privilege group">
        </a>
      </div>
  </nav>
  <div class="row m-0 flex-grow-1">
    <div class="col col-12 p-0">

      <table class="table">
        <thead>
          <tr>
            <th scope="col">{{ $t("privilegeGroups.list.code") }}</th>
            <th scope="col">{{ $t("privilegeGroups.list.name") }}</th>
            <th scope="col">{{ $t("privilegeGroups.list.privileges") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="privilegeGroup in privilegeGroups" v-bind:key="privilegeGroup.id">
            <td>{{privilegeGroup.code}}</td>
            <td>{{privilegeGroup.name}}</td>
            <td>
              <small v-for="(privilege, i) in privilegeGroup.privileges" :key="i"><i>{{privilege}}</i><br></small>
              
            </td>
            
            <td class="actions me-2 text-end">
                
                
                <a class="edit" @click="updatePrivilegeGroup(privilegeGroup)"></a>
                <a class="delete" @click="deletePrivilegeGroup(privilegeGroup)"></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>

  <privilegeGroup-new-component @created="createdPrivilegeGroup" @canceled="createPrivilegeGroupCanceled" :action="newAction" />
  <privilegeGroup-update-component @updated="updatedPrivilegeGroup" @canceled="updatePrivilegeGroupCanceled" :privilegeGroupToUpdate="privilegeGroupToUpdate" />
  <privilegeGroup-delete-component @deleted="deletedPrivilegeGroup" @canceled="deletePrivilegeGroupCanceled" :privilegeGroupToDelete="privilegeGroupToDelete" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import privilegeGroupService from '@/services/privilegeGroupService';
import eventConfig from '@/configs/eventConfig';

import PrivilegeGroupNewComponent from '../privilegeGroup/PrivilegeGroupNewComponent.vue'
import PrivilegeGroupUpdateComponent from '../privilegeGroup/PrivilegeGroupUpdateComponent.vue'
import PrivilegeGroupDeleteComponent from '../privilegeGroup/PrivilegeGroupDeleteComponent.vue'


export default {
  name: 'PrivilegeGroupsComponent',
  emits: [eventConfig.LOADED],
  props: {
    session: Object,
    superadmin: Boolean,
    loadAction: Boolean,
  },
  data: function () {
    return {
      privilegeGroups: null,
      eventConfig: eventConfig,
      newAction: false,
      privilegeGroupToUpdate: null,
      privilegeGroupToDelete: null,
      privilegeGroupToDisconnect: null,
      privilegeGroupToBan: null,
      privilegeGroupToEditPrivileges: null
    }
  },
  components: {
    'privilegeGroup-new-component': PrivilegeGroupNewComponent,
    'privilegeGroup-update-component': PrivilegeGroupUpdateComponent,
    'privilegeGroup-delete-component': PrivilegeGroupDeleteComponent,
  },
  watch: 
  { 
    loadAction: function() {
      if(this.loadAction)
      {
        this.loadPrivilegeGroups();
      }
    
    }
  },
  mounted () {
    this.loadPrivilegeGroups();
  },
  methods: 
  {

    loadPrivilegeGroups() {  
      
      privilegeGroupService.getList().then(
        response => {

          this.privilegeGroups = response;
          this.$emit(eventConfig.LOADED);
        },
        errors => {
          
        }
      );
    },
    
    createPrivilegeGroup()
    {
      this.newAction = true;
    },
    createdPrivilegeGroup(privilegeGroup)
    {
      this.newAction = false;
      this.loadPrivilegeGroups();
    },
    createPrivilegeGroupCanceled()
    {
      this.newAction = false;
    },
   
    updatePrivilegeGroup(privilegeGroup)
    {
      this.privilegeGroupToUpdate = privilegeGroup;
    },
    updatedPrivilegeGroup(privilegeGroup)
    {
      
      this.privilegeGroupToUpdate = null;
      this.loadPrivilegeGroups();
    },
    updatePrivilegeGroupCanceled()
    {
      this.privilegeGroupToUpdate = null;
    },
    
  
    deletePrivilegeGroup(privilegeGroup)
    {
      this.privilegeGroupToDelete = privilegeGroup;
    },
    deletePrivilegeGroupCanceled()
    {
      this.privilegeGroupToDelete = null;
    },
    deletedPrivilegeGroup()
    {
      this.privilegeGroupToDelete = null;
      this.loadPrivilegeGroups();
    },

  }
 
}
</script>

<style scoped lang="scss">

.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}


.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}

.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}
</style>