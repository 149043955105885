<template>

  <div id="newPrivilegeGroupModal" class="modal fade" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("privilegeGroups.new.title") }}</h5>
          <button type="button" class="btn-close" @click="cancel()" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("privilegeGroups.new.instructions") }}</p>
          <form @submit.prevent="">
            
            <div class="form-group mb-3">
              <label for="codeInput">{{ $t("privilegeGroups.new.code_label") }}</label>
              <input id="codeInput" type="text" class="form-control" :class="{ 'is-invalid': codeError}" v-model="privilegeGroup.code">      
              <div v-if="codeError" class="invalid-feedback">
                {{codeError}}
              </div>   
            </div>

            <div class="form-group mb-3">
              <label for="nameInput">{{ $t("privilegeGroups.new.name_label") }}</label>
              <input id="nameInput" type="text" class="form-control" :class="{ 'is-invalid': nameError}" v-model="privilegeGroup.name">      
              <div v-if="nameError" class="invalid-feedback">
                {{nameError}}
              </div>   
            </div>


            <div class="form-group mb-3">
              <label>{{ $t("privilegeGroups.new.privileges_label") }}</label>
              <fieldset class="scheduler-border">
              
                <div class="form-check" v-for="(privilege, index) in privileges" v-bind:key="privilege.id" >
                  <input class="form-check-input" type="checkbox" :id="'pg'+index" :value="privilege" v-model="privilegeGroup.privileges">
                  <label class="form-check-label" :for="'pg'+index">
                    {{ privilege }}
                  </label>
                </div>
              </fieldset>
            </div>


     
            <div v-if="error" class="alert alert-danger" role="alert">
              {{error}}
            </div>
            
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="cancel()">{{ $t("privilegeGroups.new.close_label") }}</button>
          <button type="button" class="btn btn-primary" @click="createPrivilegeGroup()">{{ $t("privilegeGroups.new.create_label") }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {Modal} from 'bootstrap';
import $ from 'jquery'

import eventConfig from '@/configs/eventConfig';
import privilegeGroupService from '@/services/privilegeGroupService';
import privilegeService from '@/services/privilegeService';
import groupService from '@/services/groupService';

const emptyPrivilegeGroup = {
      code: null,
      name: null,
      privileges: []
     };

export default {
  name: 'PrivilegeGroupNewComponent',
  props: {
    action: Boolean
  },
  watch: 
  { 
    action: function() {
      if(this.action)
      {
    
        Promise.all([this.loadPrivileges()]).then(() => {
          this.resetErrors();
          this.openModal();
        });
        
      }
    }
  },
  data: function () {
    return {
      privilegeGroup: emptyPrivilegeGroup,
      newModal: null,
      codeError: null,
      nameError: null,
      error: null,
      privileges: null
    }
  },
  components: {
    
  },
  mounted () {
    
    this.newModal = new Modal($('#newPrivilegeGroupModal'));
    
    this.eventDispatcher.on(eventConfig.UNAUTHORIZED, () => {
      this.cancel();
    });

  },
  methods: {
    loadPrivileges()
    {
      privilegeService.getList().then(
        response => {
          this.privileges = response;
          
        },
        errors => {
          
        }
      );
      
    },
    resetErrors()
    {
      this.codeError = null;
      this.nameError = null;
      this.error = null;
    },
    
    cancel()
    {
      this.newModal.hide();
      this.$emit(eventConfig.CANCELED);
    },
    openModal() {
      
      this.privilegeGroup = JSON.parse(JSON.stringify(emptyPrivilegeGroup));
      this.newModal.show();
    },
    
    createPrivilegeGroup() {
      
      this.resetErrors();

        privilegeGroupService.create(this.privilegeGroup).then(
          response => {
            console.log("privilegeGroupService created");
            this.newModal.hide();
            this.$emit(eventConfig.CREATED, response);
          },
          errors => {
            console.log(errors);
              this.parseErrors(errors.response.data);
          }
        );
      
    },
    parseErrors(data)
    {
      if(data.errors)
      {
        for(let error of data.errors)
        {
          
          switch(error.param)
          {
            case 'code':
              this.codeError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
            case 'name':
              this.nameError = (error.msg.msg_code) ? this.$t(error.msg.msg_code) : error.msg;
            break;
          }
        }
      }else
      {
        this.error = (data.msg_code) ? this.$t(data.msg_code) : data.msg;
      }
    }
  }
}
</script>
<style scoped>
fieldset.scheduler-border {
    border: 1px solid #ced4da;
    padding: 20px;
    border-radius: 0.25rem;

}


</style>