<template>
  
  <nav class="navbar navbar-light bg-light border-bottom">
      <div class="container-fluid ps-2 pe-0">
        <span></span>
        <a v-if="managingScreenAllowed" class="nav-link mb-2" @click="createScreen()">
            <img src="@/assets/images/ico-add.svg" alt="Add screen">
        </a>
      </div>
  </nav>
  <div v-if="readingScreenAllowed" id="screens" class="screens">
    <div class="screen ps-3 pe-3 pt-2 pb-2" v-for="screen in screens" v-bind:key="screen.id" :data-id="screen.id">
      <div class="t mt-2 mb-2">
        {{ screen.name }}
      </div>
      <div class="d-flex justify-content-between">
        <div class="actions actions-crud me-1">
          <a v-if="managingScreenAllowed" class="edit" @click="updateScreen(screen)"></a>
          <a v-if="managingScreenAllowed" class="delete" @click="deleteScreen(screen)"></a>
        </div>
        
        <div class="actions actions-pilot">
          <button v-if="pilotStreamAllowed" class="ms-1 me-1 btn btn-light" :class="{'active': (this.currentDocumentSocket && this.currentDocumentSocket.documentId == screen.id && this.currentDocumentSocket.documentType == documentSocketType.SCREEN)}" @click="changeSocket(screen)"><span class="icon cast"></span> {{ $t("screen.list.cast") }}</button>
        </div>
        
      </div>
    </div>


  </div>

  <screen-new-component @created="createdScreen" @canceled="createScreenCanceled" :session="session" :action="newAction" />
  <screen-update-component @updated="updatedScreen" @canceled="updateScreenCanceled" :session="session" :screenToUpdate="screenToUpdate" />
  <screen-delete-component @deleted="deletedScreen" @canceled="deleteScreenCanceled" :session="session" :screenToDelete="screenToDelete" />

</template>

<script>

import $ from 'jquery';
import 'jquery-ui/ui/widgets/sortable';

import privilegeConfig from '@/configs/privilegeConfig';
import screenService from '@/services/screenService';
import eventConfig from '@/configs/eventConfig';

import ScreenNewComponent from '../screen/ScreenNewComponent.vue'
import ScreenUpdateComponent from '../screen/ScreenUpdateComponent.vue'
import ScreenDeleteComponent from '../screen/ScreenDeleteComponent.vue'
import templateService from '@/services/templateService';
import DocumentSocketType from '@/model/socket/DocumentSocketType';
import ScreenSocket from '@/model/socket/ScreenSocket';

export default {
  name: 'ScreensComponent',
  emits: [eventConfig.SOCKET_CHANGE],  
  props: {
    session: Object,
    superadmin: Boolean,
    stream: Object
  },
  data: function () {
    return {
      screens: null,
      templates: null,
      eventConfig: eventConfig,
      documentSocketType: DocumentSocketType,
      newAction: false,
      screenToUpdate: null,
      screenToDelete: null,
      currentDocumentSocket: null,
      changedScreen: null,
      changeSocketOnReload: false,
      managingScreenAllowed: false,
      readingScreenAllowed: false,
      pilotStreamAllowed: false,
    }
  },
  components: {
    'screen-new-component': ScreenNewComponent,
    'screen-update-component': ScreenUpdateComponent,
    'screen-delete-component': ScreenDeleteComponent
  },
  mounted () {

    this.currentDocumentSocket = this.stream.documentSocket;

    setTimeout(() => {
      this.resize();  
    }, 300);

    $(window).resize(()=>{
      $(".screens").css('max-height', "0px" );
      this.resize();
    });


    this.loadTemplates();

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.MANAGE_SCREEN)) )
    {
      this.managingScreenAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.READ_SCREEN)) )
    {
      this.readingScreenAllowed = true;
    }

    if(this.superadmin || (this.session.privileges && this.session.privileges.includes(privilegeConfig.PILOT_STREAM)) )
    {
      this.pilotStreamAllowed = true;
    }
  },
  methods: 
  {
    resize() {
      var streamContainer = $("#streamContainer").height();
      $(".screens").css('max-height', (streamContainer-64)+"px" );
    },
    loadTemplates() { 
      templateService.getListScreen().then(
        response => {
          this.templates = response;
          this.loadScreens();
        },
        errors => {
          
        }
      );
    },
    loadScreens() {  
      
      screenService.getList(this.session).then(
        response => {

          this.screens = response;

          if(this.changeSocketOnReload && this.changedScreen)
          {
            this.changeSocket(this.changedScreen);
            this.changedScreen = null;
            this.changeSocketOnReload = false;
          }
          
          $("#screens").sortable({
            update: () => {
              var orderedScreensId = [];
                $("#screens .screen").each((i,elem)=>{
                  if($(elem).attr('data-id') != undefined)
                  {
                    orderedScreensId.push( $(elem).attr('data-id') );
                  }
                });

              this.updateScreensRank(orderedScreensId);
              
            }
          });
          
        },
        errors => {
          
        }
      );
    },
    updateScreensRank(orderedScreensId)
    {
      
      screenService.reorder(orderedScreensId).then(
        response => {
          
        },
        errors => {
          
        }
      );
    },
    createScreen()
    {
      this.newAction = true;
    },
    createdScreen(screen)
    {
      this.newAction = false;
      this.loadScreens();
    },
    createScreenCanceled()
    {
      this.newAction = false;
    },
   
    updateScreen(screen)
    {
      this.screenToUpdate = screen;
    },
    updatedScreen(screen)
    {
      
      this.screenToUpdate = null;
      if(this.currentDocumentSocket && this.currentDocumentSocket.documentId == screen.id && this.currentDocumentSocket.documentType == this.documentSocketType.SCREEN)
      {
        this.changedScreen = screen;
        this.changeSocketOnReload = true;
      }
      this.loadScreens();
    },
    updateScreenCanceled()
    {
      this.screenToUpdate = null;
    },    
    
    updateTemplateScreen(screen)
    {
      screen.session = this.session.id;
      screenService.update(screen).then(
        response => {
          this.updatedScreen(response);
        },
        errors => {
          
        }
      );
    },

    deleteScreen(screen)
    {
      this.screenToDelete = screen;
    },
    deleteScreenCanceled()
    {
      this.screenToDelete = null;

    },
    deletedScreen()
    {
      this.screenToDelete = null;
      this.loadScreens();
    },
    changeSocket(screen)
    {
      
      var screenWithTemplate = JSON.parse(JSON.stringify(screen));
      for(const t of this.templates)
      {
        if(t.id == screenWithTemplate.template)
        {
          screenWithTemplate.template = t;
          break;
        }
      }
      let doc = new ScreenSocket(screen.id, screenWithTemplate);
      
      this.$emit(eventConfig.SOCKET_CHANGE, doc);
    }
  }
 
}
</script>

<style scoped lang="scss">

.screens {
  max-height: 0px;
  overflow-y: scroll;
}

.screen.active {
  background-color: #E0E0E0;
}

.t {
  line-height: 35px;
  padding-left: 45px;
  background-image: url(@/assets/images/ico-screen.svg);
  background-repeat: no-repeat;
  background-position: top left;
}



.actions a {
    background-repeat: no-repeat;
    background-position: center center;
    display: inline-block;
    width: 40px;
    height: 40px;
}


.actions a.edit {
  background-image: url(@/assets/images/ico-edit.svg);
}
.actions a.delete {
  background-image: url(@/assets/images/ico-trash.svg);
}

.btn.btn-light {
  border: 1px solid black;
}


.btn.active {
  background-color: black;
  color: white;
}

.btn .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 0px 6px;
}


.btn .icon.cast {
  background-image: url(@/assets/images/ico-cast.svg);
}
.btn.active .icon.cast {
  background-image: url(@/assets/images/ico-cast-on.svg);
}
</style>