import apiService from './apiService';

export default class privilegeGroupService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/privilegeGroup/list/")
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

   
    static create (privilegeGroup) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/privilegeGroup/new", privilegeGroup)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (privilegeGroup) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/privilegeGroup/edit", privilegeGroup)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static delete (privilegeGroup) {
        
        return new Promise((resolve, reject) => {
            apiService.delete("/privilegeGroup/remove/"+privilegeGroup.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

}
