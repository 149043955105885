import apiService from './apiService';

export default class qaService {


    static getList (qa) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/qa/listBySession/"+qa.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static reorder (qaIds) {
        
        return new Promise((resolve, reject) => {
            const data = {
                qaIds: qaIds
            }
            apiService.post("/qa/reorder/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static create (qa) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/qa/new", qa)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (qa) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/qa/edit", qa)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 
    
    
    static updateDisplay (qa) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/qa/editDisplay", qa)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    } 


    static delete (data) {

        return new Promise((resolve, reject) => {
            apiService.post("/qa/remove/", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
