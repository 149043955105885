import apiService from './apiService';

export default class privilegeService {


    static getList () {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/privilege/list/", false)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

   

}
