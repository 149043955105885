class privilegeConfig {
  static READ_MESSAGE = process.env.VUE_APP_PRIVILEGE_READ_MESSAGE;
  static SEND_MESSAGE = process.env.VUE_APP_PRIVILEGE_SEND_MESSAGE;
  static READ_SESSION = process.env.VUE_APP_PRIVILEGE_READ_SESSION;
  static MANAGE_SESSION = process.env.VUE_APP_PRIVILEGE_MANAGE_SESSION;
  static EXPORT_SESSION = process.env.VUE_APP_PRIVILEGE_EXPORT_SESSION; 
  static READ_GROUP = process.env.VUE_APP_PRIVILEGE_READ_GROUP;
  static MANAGE_GROUP = process.env.VUE_APP_PRIVILEGE_MANAGE_GROUP;
  static READ_VOTER = process.env.VUE_APP_PRIVILEGE_READ_VOTER;
  static MANAGE_VOTER = process.env.VUE_APP_PRIVILEGE_MANAGE_VOTER;  
  static READ_CHANNEL = process.env.VUE_APP_PRIVILEGE_READ_CHANNEL;
  static PILOT_CHANNEL = process.env.VUE_APP_PRIVILEGE_PILOT_CHANNEL;
  static MANAGE_CHANNEL = process.env.VUE_APP_PRIVILEGE_MANAGE_CHANNEL;
  static READ_QUESTION = process.env.VUE_APP_PRIVILEGE_READ_QUESTION;
  static MANAGE_QUESTION = process.env.VUE_APP_PRIVILEGE_MANAGE_QUESTION;
  static READ_STREAM = process.env.VUE_APP_PRIVILEGE_READ_STREAM;
  static PILOT_STREAM = process.env.VUE_APP_PRIVILEGE_PILOT_STREAM;
  static MANAGE_STREAM = process.env.VUE_APP_PRIVILEGE_MANAGE_STREAM;
  static READ_SCREEN = process.env.VUE_APP_PRIVILEGE_READ_SCREEN;
  static MANAGE_SCREEN = process.env.VUE_APP_PRIVILEGE_MANAGE_SCREEN;
  static READ_QAS = process.env.VUE_APP_PRIVILEGE_READ_QAS;
  static MANAGE_QAS = process.env.VUE_APP_PRIVILEGE_MANAGE_QAS;
 }

module.exports = privilegeConfig;