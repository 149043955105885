import apiService from './apiService';

export default class channelService {


    static getList (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/channel/listBySession/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static isSlugAvailable (slug, session) {
        
        return new Promise((resolve, reject) => {
            const data = {
                slug: slug,
                session: session.id
            }
            
            apiService.post("/channel/isSlugBySessionIdAvailable", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }

    static create (channel) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/channel/new", channel)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (channel) {
        
        return new Promise((resolve, reject) => {
            const data = {
                id: channel.id,
                slug: channel.slug,
                name: channel.name,
                session: channel.session
            }
            
            apiService.post("/channel/edit", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  
    
    static updateChannelStream (channel) {
        
        return new Promise((resolve, reject) => {
            const data = {
                id: channel.id,
                stream: channel.stream,
                session: channel.session
            }
            console.log(data)
            apiService.post("/channel/editStream", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  

    static delete (channel) {
        
        return new Promise((resolve, reject) => {
            apiService.post("/channel/remove", channel)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
