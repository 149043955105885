<template>
  <div id="page" class="p-0">
    <home-navbar-component :superadmin="superadmin" :state="state" />
    <users-groups-component :superadmin="superadmin" />
  </div>
</template>


<script>
import HomeNavbarComponent from '@/components/navigation/HomeNavbarComponent.vue';

import UsersGroupsComponent from "@/components/usersgroups/UsersGroupsComponent.vue";
import authService from '@/services/authService';
import sessionService from '@/services/sessionService';
import eventConfig from '@/configs/eventConfig';
import privilegeConfig from '@/configs/privilegeConfig';

export default {
  name: 'UsersView',
  data: function () {
    return {
      session: null,
      superadmin: false,
      state: 'users'
    }
  },
  params: {
    id: String
  },
  components: {
    'home-navbar-component': HomeNavbarComponent,
    'users-groups-component': UsersGroupsComponent,
  },
  mounted () {
    
    this.isSuperAdmin();
    
  },
  methods: {

    isSuperAdmin() {
      authService.isSuperAdmin().then(
        response => {
          this.superadmin = true;
          
        },
        error => {
          this.superadmin = false;
          
        }
      );
    },
    
  }
}
</script>