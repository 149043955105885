import apiService from './apiService';

export default class streamService {


    static getList (session) {
        
        return new Promise((resolve, reject) => {
            
            apiService.get("/stream/listBySession/"+session.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }


    static create (stream) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/stream/new", stream)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    }  
    
    static update (stream) {
        
        return new Promise((resolve, reject) => {
            
            apiService.post("/stream/edit", stream)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }      

    static updateDocumentSocket (data) {
        
        return new Promise((resolve, reject) => {

            apiService.post("/stream/editDocumentSocket", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);   
            });
         });
       
    } 

    static delete (data) {
        
        return new Promise((resolve, reject) => {
            apiService.post("/stream/remove", data)
            .then(response => {
                resolve(response.data);
            })
            .catch(function (error) {
                reject(error);
            });
         });
       
    }  


}
